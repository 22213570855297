import axios from "axios";
import { HOST } from "./../constants/url";
import { message } from "antd";
import { getQueryString, trimStrings } from "../helper/common";
import BasicAlertPopup from "components/Basic/BasicAlertPopup/BasicAlertPopup";

const messageMissingAuth = {
  en: "Login session has expired, please log in again",
  vi: "Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại",
  cn: "登录会话已过期，请重新登录",
  in: "Sesi login telah berakhir, silakan login kembali",
  ko: "로그인 세션이 만료되었습니다. 다시 로그인해 주세요",
  ms: "Sesi log masuk telah tamat tempoh, sila log masuk semula",
  th: "เซสชั่นการเข้าสู่ระบบหมดอายุแล้ว กรุณาเข้าสู่ระบบอีกครั้ง"
};
const messageWhenMaintain = {
  en: "The system is maintenance. Please come back later",
  vi: "Hệ thống đang bảo trì. Vui lòng quay lại sau",
  cn: "系统正在维护中。请待会再过来",
  in: "Sistemnya adalah pemeliharaan. Silakan kembali lagi nanti",
  ko: "시스템은 유지보수입니다. 나중에 다시 오시기 바랍니다",
  ms: "Sistem ini adalah penyelenggaraan. Sila balik nanti",
  th: "อยู่ในระหว่างการบำรุงรักษาระบบ โปรดกลับมาใหม่"
};

function send({
  method = "get",
  path,
  data = null,
  query = null,
  headers = {},
  newUrl,
  disableAuth,
}) {
  return new Promise((resolve) => {
    let url = HOST + `${path}${getQueryString(query)}`;
    if (newUrl) {
      url = `${newUrl}${getQueryString(query)}`;
    }
    const dataString = window.localStorage.getItem("data");
    if (!disableAuth && dataString) {
      const newData = JSON.parse(dataString);
      headers.authorization = newData.token ? `Bearer ${newData.token}` : '';
    }
    axios({
      method,
      url,
      data: trimStrings(data),
      headers,
    })
      .then((result) => {
        const data = result.data;
        return resolve(data);
      })
      .catch((error) => {
        const { response = {} } = error;
        const result = response.data ? response.data : null;
        const { statusCode } = result;

        if (statusCode === 599) {
          BasicAlertPopup({
            title: messageWhenMaintain?.[window.localStorage.getItem("lang")],
            color: "danger",
            icon: "danger",
            showCloseButton: true
          });
        }
        else if (statusCode === 401 || statusCode === 505 || statusCode === 403) {
          message.config({ maxCount: 1 });
          message.warn(
            messageMissingAuth?.[window.localStorage.getItem("lang")]
          );
          setTimeout(() => {
            window.localStorage.removeItem("data");
            window.localStorage.removeItem("token");
            window.localStorage.removeItem("isUserLoggedIn");
            window.location.href = "/login";
          }, 1000);
        } else {
          return resolve(result);
        }
      });
  });
}

export default {
  send,
};
