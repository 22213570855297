import Request from "./request";

export default class otpService {
  static async sendEmailOTP(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: "POST",
        path: "AppUsers/sendEmailOTP",
        data,
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result;

        if (statusCode === 200) {
          return resolve({ isSuccess: true, data });
        } else {
          return resolve({ isSuccess: false, message, error });
        }
      });
    });
  }
}
