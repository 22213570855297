import React, { useState } from "react";
import { injectIntl } from "react-intl";
import "./index.scss";
import useCommonHook from "hooks/useCommonHook";
import { Header } from "components/Layout/Header/Header";
import BasicSegmented from "components/Basic/BasicSegmented/BasicSegmented";
import { IconCreditCardSettings } from "assets/icons/iconAdvanced";
import BasicButton from "components/Basic/BasicButton/BasicButton";
import { IconAddCircle } from "assets/icons/iconBasic";
import { IconIconCrypton } from "assets/icons/iconAdvanced";
import { IconCopy } from "assets/icons/iconBasic";
import { useHistory, useLocation } from "react-router-dom";
import { usePaymentMethods } from "hooks/usePaymentMethod";
import { PAYMENT_METHOD_USAGE } from "hooks/usePaymentMethod";
import { MAX_PAYMENT_METHOD_OF_USER } from "hooks/usePaymentMethod";

const Setting = () => {
  const { translate } = useCommonHook();
  const segmentedList = [
    {
      value: PAYMENT_METHOD_USAGE.USDT,
      label: PAYMENT_METHOD_USAGE.USDT,
    },
    {
      value: PAYMENT_METHOD_USAGE.PIT,
      label: PAYMENT_METHOD_USAGE.PIT,
    },
    {
      value: "ATM_BANK",
      label: translate("label_bank"),
    },
  ];

  const itemsTabShow = {
    USDT: {
      title: translate("Your_wallet"),
      content: translate("wallet"),
      buttonText: translate("Add_wallet_USDT"),
      card: {
        backgroundClass: "Setting_card_bgUSDT",
        label_top: translate("Network"),
        label_bottom: translate("Wallet_address"),
      },
    },
    PIT: {
      title: translate("Your_wallet"),
      content: translate("wallet"),
      buttonText: translate("Add_wallet_PIT"),
      card: {
        backgroundClass: "Setting_card_bgPIT",
        label_top: translate("Network"),
        label_bottom: translate("Wallet_address"),
      },
    },
    ATM_BANK: {
      title: translate("Your_bank_account"),
      content: translate("bank_account"),
      buttonText: translate("Add_bank_account"),
      card: {
        backgroundClass: "Setting_card_bgBANK",
        label_top: translate("Account_owner"),
        label_bottom: translate("account_number"),
      },
    },
  };

  const MAX_WALLET = 1;
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [tab, setTab] = useState(
    searchParams?.get("tab") || PAYMENT_METHOD_USAGE.USDT
  );
  const { data, setData, loading, showPopupService } = usePaymentMethods(tab)
  const { copyToClipboard } = useCommonHook();

  const handleChangeTab = (tab) => {
    setTab(tab);
    setData([]);
    const params = {};
    if (tab) {
      params.tab = tab;
    }
    const urlSearchParams = new URLSearchParams(params);
    history.replace({
      pathname: location.pathname,
      search: urlSearchParams.toString(),
    });
  };

  const handleSubStringAddress = (string) => {
    const numberToSubString = 8;
    if (string?.length > numberToSubString * 2) {
      return (
        string?.substr(0, numberToSubString) +
        "..." +
        string?.substr(-numberToSubString)
      );
    }
    return string;
  };

  const handleClickButton = () => {
    if (
      (data?.length >= MAX_PAYMENT_METHOD_OF_USER && tab === PAYMENT_METHOD_USAGE.USDT) ||
      (data?.length >= MAX_PAYMENT_METHOD_OF_USER && tab === PAYMENT_METHOD_USAGE.PIT) ||
      (data?.length >= MAX_PAYMENT_METHOD_OF_USER && tab === PAYMENT_METHOD_USAGE.ATM_BANK)
    ) {
      showPopupService()
    } else {
      history.push("/payment-method-insert/" + tab);
    }
  };

  return (
    <div className="Setting">
      <div className="Setting_backgroundTop" />
      <Header title={translate("setting")} backgroundColor="transparent" />
      <BasicSegmented
        options={segmentedList}
        backgroudColor="transparent"
        value={tab}
        onChange={handleChangeTab}
        block={MAX_WALLET}
      />

      <div className="Setting_content">
        <h5 className="Setting_title">{itemsTabShow?.[tab]?.title}</h5>
        {data.length > 0 &&
          data.map((item, index) => (
            <div
              key={index}
              className={`Setting_card ${itemsTabShow?.[tab]?.card?.backgroundClass}`}
            >
              <div className="Setting_card_top">
                {tab === PAYMENT_METHOD_USAGE.USDT && (
                  <>
                    <IconIconCrypton height={28} width={28} />
                    <p>{translate("Your_USDT_wallet")}</p>
                  </>
                )}
                {tab === PAYMENT_METHOD_USAGE.PIT && (
                  <>
                    <img src={"/logo.png"} alt="" height={28} width={28} />
                    <p>{translate("Your_PIT_wallet")}</p>
                  </>
                )}
                {tab === PAYMENT_METHOD_USAGE.ATM_BANK && (
                  <>
                    <img
                      src={item?.paymentMethodImageUrl}
                      alt=""
                      height={28}
                      width={28}
                    />
                    <p>
                      {translate("label_bank")}{" "}
                      {item?.paymentMethodName}
                    </p>
                  </>
                )}
              </div>
              <div className="Setting_card_line" />
              <div className="Setting_card_content">
                <div>
                  <span className="Setting_card_content_label">
                    {itemsTabShow?.[tab]?.card?.label_top}
                  </span>
                  <p className="Setting_card_content_value">
                    {item?.paymentMethodReceiverName}
                  </p>
                </div>
                <div>
                  <span className="Setting_card_content_label">
                    {itemsTabShow?.[tab]?.card?.label_bottom}
                  </span>
                  <p className="Setting_card_content_value">
                    <span>
                      {handleSubStringAddress(
                        item?.paymentMethodIdentityNumber
                      )}
                    </span>
                    <IconCopy
                      onClick={() => {
                        copyToClipboard(item?.paymentMethodIdentityNumber);
                      }}
                    />
                  </p>
                </div>
              </div>
            </div>
          ))}

        {data?.length === 0 && !loading && (
          <div className="Setting_box">
            <IconCreditCardSettings className="Setting_box_icon" />
            <p className="Setting_box_text">
              {translate(
                "You_need_to_add_{number}_{type}_to_make_the_transaction",
                {
                  number: MAX_WALLET > 9 ? MAX_WALLET : "0" + MAX_WALLET,
                  type: itemsTabShow?.[tab]?.content,
                }
              )}
            </p>
          </div>
        )}
        <BasicButton
          outlined={true}
          color="primary"
          width="fit-content"
          className="Setting_button"
          onClick={() => handleClickButton()}
          disabled={loading}
        >
          <IconAddCircle />
          <span>{itemsTabShow?.[tab]?.buttonText}</span>
        </BasicButton>
      </div>
    </div>
  );
};

export default injectIntl(Setting);
