import moment from "moment";
import { useState } from "react";

export const DATE_DEFAULT_FILTER = {
  filter: {},
  skip: 0,
  limit: 10,
  order: {
    key: "createdAt",
    value: "desc",
  },
};

export default function useDateFilter({ callback, defaultFilter }) {
  const [filter, setFilter] = useState({
    ...(defaultFilter || DATE_DEFAULT_FILTER),
    startDate: moment()
      .subtract(1, "month")
      .startOf("days")
      .add(7, "hours")
      .add(1, "seconds")
      .toDate(),
    endDate: moment().add(1, "month").endOf("days").add(7, "hours").toDate(),
  });
  const [startDate, setStartDate] = useState(moment().subtract(1, "month"));
  const [endDate, setEndDate] = useState(moment().add(1, "month"));
  function onChangeStartDate(date) {
    const newFilter = {
      ...filter,
      startDate: date
        ? moment(date)
            .startOf("days")
            .add(7, "hours")
            .add(1, "seconds")
            .toDate()
        : undefined,
    };
    setStartDate(date);
    if (!date) {
      delete newFilter.startDate;
    }
    setFilter(newFilter);
    callback && callback(newFilter);
  }

  function onChangeEndDate(date) {
    const newFilter = {
      ...filter,
      endDate: date ? moment(date).endOf("days").add(7, "hours").toDate() : undefined,
    };
    setEndDate(date);
    if (!date) {
      delete newFilter.endDate;
    }
    setFilter(newFilter);
    callback && callback(newFilter);
  }

  function disabledDate(current) {
    return current && current < moment(startDate).startOf("day");
  }

  const onKeyDown = (e) => {
    e.preventDefault();
    return false;
  };
  return {
    startDate,
    endDate,
    onChangeEndDate,
    onChangeStartDate,
    disabledDate,
    onKeyDown,
    filter,
    setFilter,
  };
}
