import React, { useContext } from "react";
import "./Header.scss";
import { useHistory } from "react-router-dom";
import { routes } from "App";
import { IconArrowLeft } from "assets/icons/iconBasic";
import { IconHomeSmile } from "assets/icons/iconBasic";
import { IconFilter } from "assets/icons/iconBasic";
import { IconHistory } from "assets/icons/iconBasic";
import { IconRoundSortHorizontal } from "assets/icons/iconBasic";
import { IconInfoCircle } from "assets/icons/iconBasic";
import { IntlContext } from "react-intl";
import { LanguageEnglish } from "assets/icons/iconLanguage";
import { IconChecklist } from "assets/icons/iconBasic";
import { IconTrashBinMinimalistic } from "assets/icons/iconBasic";
import { LanguageCambodian } from "assets/icons/iconLanguage";
import { LanguageChinese } from "assets/icons/iconLanguage";
import { LanguageKorean } from "assets/icons/iconLanguage";
import { LanguageMalaysian } from "assets/icons/iconLanguage";
import { LanguageThailand } from "assets/icons/iconLanguage";
import { LanguageVietnamese } from "assets/icons/iconLanguage";
import { Notification } from "components/Notification";
import UserInfoBasic from "components/Advanced/UserBasicInfo/UserInfoBasic";
import { APP_LANGUAGE } from "constants/languageConstant";

export function Header(props) {
  const {
    className,
    backgroundColor = "primary", // ["primary", "secondary", "danger", "success", "info", "transparent"];
    backgroundGradient = true,
    backgroundClassName = "",
    backgroundStyle = {}, // custom inline
    colorItem = "light", // ["light", "dark"];
    title, // text or component
    titleClassName = "",
    iconsLeft = "back", //xem các loại mặc định ở dưới kia đỡ phải import
    iconsRight = [
      {
        icon: "",
        onClick: () => { }, // cách 2 {icon: "back" | <IconArrowLeft />,onClick: () => {}}, nếu muốn nhiều icon và có thể thao tác thay thế onClick cũ
        custom: undefined
      },
    ],
    iconsBackgroundColor = "transparent", // ["primary", "secondary", "danger", "success", "info", "transparent"];
    showLogo = false,
    showLanguage = false,
    showProfile = false,
    backHome = false
  } = props;

  const history = useHistory();
  const intlContext = useContext(IntlContext);

  const headerBackground = `background_${backgroundColor || "primary"}${backgroundGradient ? "_gradiant" : ""}`;
  const colorsItemToShow =
    colorItem === "dark" ? "headerMain_dark" : "headerMain_light";
  const iconsBackground = `background_${iconsBackgroundColor}`;

  const handlePush = () => {
    if (backHome === true) {
      history.push("/")
    } else {
      history.goBack();
    }
  }

  const icons = {
    back: {
      icon: <IconArrowLeft />,
      onClick: () => handlePush(),
    },
    notification: {
      icon: <Notification />,
      onClick: () => {
        history.push(routes.notification.path);
      },
    },
    home: {
      icon: <IconHomeSmile />,
      onClick: () => {
        history.push(routes.home.path);
      },
    },
    filter: {
      icon: <IconFilter />,
    },
    history: {
      icon: <IconHistory />,
    },
    sortHorizontal: {
      icon: <IconRoundSortHorizontal />,
    },
    info: {
      icon: <IconInfoCircle />,
    },
    checkList: {
      icon: <IconChecklist />,
    },
    delete: {
      icon: <IconTrashBinMinimalistic />,
    },
  };
  const RenderIcons = ({ iconsSelected }) => {
    const list = Array.isArray(iconsSelected)
      ? iconsSelected
      : typeof iconsSelected === "object" && iconsSelected !== null
        ? [iconsSelected]
        : null;

    if (list?.length > 0) {
      return list.map((item, i) => {
        if (icons?.[item?.icon]?.icon || item?.icon) {
          return (
            <div
              key={`RenderIcons_${i}`}
              className={`headerMain_icon ${iconsBackground}`}
              onClick={() => {
                const defaultOnClick = icons?.[item?.icon]?.onClick;
                const newOnClick = item?.onClick;
                if (newOnClick) {
                  newOnClick();
                } else {
                  if (defaultOnClick) {
                    defaultOnClick();
                  }
                }
              }}
            >
              {icons?.[item?.icon]?.custom || item?.custom}
              {icons?.[item?.icon]?.icon || item?.icon}
            </div>
          );
        }
      });
    } else {
      if (icons?.[iconsSelected]?.icon) {
        return (
          <div
            className={`headerMain_icon ${iconsBackground}`}
            onClick={icons?.[iconsSelected]?.onClick}
          >
            {icons?.[iconsSelected]?.custom}
            {icons?.[iconsSelected]?.icon}
          </div>
        );
      }
    }
    return <></>;
  };

  const iconsLanguage = {
    km: <LanguageCambodian height={20} width={20} />,
    cn: <LanguageChinese height={20} width={20} />,
    en: <LanguageEnglish height={20} width={20} />,
    ko: <LanguageKorean height={20} width={20} />,
    ms: <LanguageMalaysian height={20} width={20} />,
    th: <LanguageThailand height={20} width={20} />,
    vi: <LanguageVietnamese height={20} width={20} />,
  };
  return (
    <header id="header" className={`${className}`}>
      <div
        className={`max_width_screen_project headerBackground ${headerBackground} ${backgroundClassName}`}
        style={{ ...backgroundStyle }}
      />
      <div className={`headerMain ${colorsItemToShow}`}>
        <div className="headerMain_left">
          {showLogo && (
            <div className="headerMain_logo">
              <img src="/logo.png" alt="" /> <span>PITCOIN</span>
            </div>
          )}
          {showProfile && <UserInfoBasic />}
          <RenderIcons iconsSelected={iconsLeft} />
        </div>
        <div className={`headerMain_center ${titleClassName}`}>{title}</div>
        <div className="headerMain_right">
          <RenderIcons iconsSelected={iconsRight} />
          {showLanguage && (
            <div
              className="headerMain_language"
              onClick={() => history.push(routes.settingLanguage.path)}
            >
              {iconsLanguage?.[intlContext?.locale]}
              <span>{APP_LANGUAGE[intlContext?.locale]?.label}</span>
            </div>
          )}
        </div>
      </div>
    </header>
  );
}
