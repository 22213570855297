import ForgotPass from 'Page/ForgotPass'
import { Introduce } from 'Page/Introduce'
import Saving from 'Page/Saving'
import PaymentMethod from 'Page/PaymentMethod'
import PaymentMethodInsert from 'Page/PaymentMethodInsert'
import React, { lazy, Suspense, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Layout from './../src/Page/Layout'
import WelcomePage from 'Page/WelcomPage'
import { THEME } from 'constants/url'
import { Splash } from 'components/Splash'
const NotificationDetail = lazy(() => import('./../src/Page/Notifications/Detail'))
const EventDetail = lazy(() => import('./../src/Page/Notifications/eventDetail'))
const InstructDetail = lazy(() => import('./../src/Page/Instruct/detail'))
const Home = lazy(() => import('./../src/Page/Home'))
const Register = lazy(() => import('./../src/Page/Register'))
const NationRegister = lazy(() => import('./../src/Page/Register/NationRegister'))
const Login = lazy(() => import('./../src/Page/Login'))
const SettingLanguage = lazy(() => import('./Page/SettingLanguage'))
const Branch = lazy(() => import('./../src/Page/Branch'))
const Notifications = lazy(() => import('./../src/Page/Notifications'))
const Organization = lazy(() => import('./../src/Page/Organization'))
const LeaderBoard = lazy(() => import('./../src/Page/LeaderBoard'))
const Instruct = lazy(() => import('./../src/Page/Instruct/index'))
const Shop = lazy(() => import('./Page/Shop'))
const DepositUSDT = lazy(() => import('./Page/DepositUSDT'))
const DepositPIT = lazy(() => import('./Page/DepositPIT'))
const DepositBank = lazy(() => import('./Page/DepositBank'))
const WithdrawUSDT = lazy(() => import('./Page/WithdrawUSDT'))
const WithdrawBank = lazy(() => import('./Page/WithdrawBank'))
const WithdrawPIT = lazy(() => import('./Page/WithdrawPIT'))
const TransferPit = lazy(() => import('./Page/TransferPit'))
const TransferCommission = lazy(() => import('./Page/TransferCommission'))
const Support = lazy(() => import('./../src/Page/Support'))
const Profile = lazy(() => import('./../src/Page/Profile/profile'))
const MyWallet = lazy(() => import('./Page/MyWallet'))
const USDTWallet = lazy(() => import('./Page/MyWallet/USDTWallet'))
const PITWallet = lazy(() => import('./Page/MyWallet/PITWallet'))
const RoseWallet = lazy(() => import('./Page/MyWallet/RoseWallet'))
const DetailUSDTWallet = lazy(() => import('./Page/MyWallet/USDTWallet/detail'))
const DetailPITWallet = lazy(() => import('./Page/MyWallet/PITWallet/detail'))
const DetailDraw = lazy(() => import('./Page/MyWallet/USDTWallet/DetailDraw'))
const DetailDrawPIT = lazy(() => import('./Page/MyWallet/PITWallet/DetailDraw'))
const DetailTransactionPIT = lazy(() => import('./Page/MyWallet/PITWallet/detailTransaction'))
const RoseWalletById = lazy(() => import('./Page/MyWallet/RoseWallet/detail'))
const AccountInfo = lazy(() => import('./Page/AccountInfo'))
const ChangePass = lazy(() => import('./Page/ChangePass'))
const Factory = lazy(() => import('./Page/Factory'))
const Staking = lazy(() => import('./Page/Staking'))
const Two2Fa = lazy(() => import('./Page/Login/two2FA'))
const Two2FaVerify = lazy(() => import('./Page/Two2Fa/two2Fa'))
const PaymentGateways = lazy(() => import('./Page/PaymentGateways'))
const WithdrawGateways = lazy(() => import('./Page/WithdrawGateways'))
const PaymentGatewaysFilter = lazy(() => import('./Page/PaymentGatewaysFilter'))
const BranchDetail = lazy(() => import("./Page/Branch/BranchDetail"));
const Fund = lazy(() => import('./Page/Fund/index'));
const TransactionPass = lazy(() => import("./Page/TransactionPass"));
const ForgotTransactionPass = lazy(() => import("./Page/TransactionPass/fogotTransactionPass"));
const VerifyPhone = lazy(() => import("./Page/VerifyPhone"));
const AccountAuth = lazy(() => import("./Page/AccountAuthen"));
const ShopHistoryRentMachine = lazy(() => import("./Page/ShopHistoryRentMachine"));
const FactoryHistory = lazy(() => import("./Page/FactoryHistory"));
const FactoryTransfer = lazy(() => import("./Page/FactoryTransfer"));
const ErrorBank = lazy(() => import("./Page/ErrorBank"));
const OvernightProfit = lazy(() => import("./Page/OvernightProfit"));
const DetailMachineShopRoute = lazy(() => import("./Page/ShopHistoryRentMachine/DetailMachineShopRoute"));
const DetailFactoryRoute = lazy(() => import("./Page/FactoryTransfer/DetailFactoryRoute"));

export const routes = {
  welcome: {
    path: '/welcome',
    component: WelcomePage,
    isAuth: false,
    label: 'splash'
  },
  nation: {
    path: '/register-nation',
    component: NationRegister,
    isAuth: false,
    label: 'Chọn quốc gia'
  },
  login: {
    path: '/login',
    component: Login,
    isAuth: false,
    label: 'Đăng nhập'
  },
  register: {
    path: '/register',
    component: Register,
    isAuth: false,
    label: 'Đăng ký'
  },
  forgotPass: {
    path: '/forgot',
    component: ForgotPass,
    isAuth: false,
    label: 'Quên mật khẩu'
  },
  settingLanguage: {
    path: '/setting-language',
    component: SettingLanguage,
    isAuth: false,
    label: 'Dịch'
  },
  introduce: {
    path: '/introduce',
    component: Introduce,
    isAuth: true,
    label: 'Giới thiệu',
    showFooterMobile: true
  },
  myWallet: {
    path: '/my-wallet',
    component: MyWallet,
    isAuth: true,
  },
  accountInfo: {
    path: '/profile/account',
    component: AccountInfo,
    isAuth: true,
    label: 'Thông tin tài khoản'
  },
  changePassword: {
    path: '/profile/change-password',
    component: ChangePass,
    isAuth: true,
    label: 'Đổi mật khẩu'
  },
  shop: {
    path: '/shop',
    component: Shop,
    isAuth: true,
    label: 'Cửa hàng',
    showFooterMobile: true
  },
  factory: {
    path: '/factory',
    component: Factory,
    isAuth: true,
    label: 'Nhà máy',
    showFooterMobile: true
  },
  transactionPass: {
    path: "/profile/transaction-password",
    component: TransactionPass,
    isAuth: true,
    label: "Đổi mật khẩu giao dịch",
  },
  verifyPhone: {
    path: "/profile/verify-phonenumber",
    component: VerifyPhone,
    isAuth: true,
    label: "Xác minh số điện thoại",
  },
  accountAuth: {
    path: "/profile/account-auth",
    component: AccountAuth,
    isAuth: true,
    label: "Xác thực tài khoản",
  },
  forgotTransactionPass: {
    path: "/transaction/forgot-password",
    component: ForgotTransactionPass,
    isAuth: true,
    label: "Đổi mật khẩu giao dịch",
  },
  branch: {
    path: '/management/branch',
    component: Branch,
    isAuth: true,
    label: <FormattedMessage id="branch" />,
    showFooterMobile: true
  },
  accountInformation: {
    path: "/management/branch/account/:id",
    component: BranchDetail,
    isAuth: true,
    isHidden: true,
  },
  organization: {
    path: '/management/organization',
    component: Organization,
    isAuth: true,
    label: <FormattedMessage id="organization" />
  },
  notification: {
    path: '/management/notification',
    component: Notifications,
    isAuth: true,
    label: <FormattedMessage id="notification" />
  },
  notificationDetail: {
    path: '/management/notification/:id',
    component: NotificationDetail,
    isAuth: true,
    isHidden: true,
    label: <FormattedMessage id="notification" />
  },
  eventDetail: {
    path: '/management/event/:id',
    component: EventDetail,
    isAuth: true,
    isHidden: true,
    label: <FormattedMessage id="notification" />
  },
  leaderBoard: {
    path: '/leader-board',
    component: LeaderBoard,
    isAuth: true,
    label: <FormattedMessage id="leader_board" />,
    showFooterMobile: true
  },
  saving: {
    path: '/management/saving',
    component: Saving,
    isAuth: true,
    label: <FormattedMessage id="saving" />
  },
  managementProfile: {
    path: '/management/profile',
    component: Profile,
    isAuth: true,
    label: 'Tài khoản',
    showFooterMobile: true
  },
  paymentMethod: {
    path: '/payment-method',
    component: PaymentMethod,
    isAuth: true
  },
  paymentMethodInsert: {
    path: '/payment-method-insert/:type',
    component: PaymentMethodInsert,
    isAuth: true
  },
  paymentGateways: {
    path: '/payment-gateways',
    component: PaymentGateways,
    isAuth: true
  },
  withdrawGateways: {
    path: '/withdraw-gateways',
    component: WithdrawGateways,
    isAuth: true
  },
  paymentGatewaysFilter: {
    path: '/payment-gateways-filter',
    component: PaymentGatewaysFilter,
    isAuth: true
  },
  support: {
    path: '/management/support',
    component: Support,
    isAuth: true,
    label: 'Hỗ trợ'
  },
  staking: {
    path: '/management/staking',
    isAuth: true,
    component: Staking
  },
  two2Fa: {
    path: '/two2fa',
    isAuth: false,
    component: Two2Fa
  },
  two2FaVerify: {
    path: '/two2FaVerify',
    isAuth: true,
    component: Two2FaVerify
  },
  instruct: {
    path: '/instruct',
    isAuth: true,
    component: Instruct
  },
  instructDetail: {
    path: '/instruct/detail/:id',
    component: InstructDetail,
    isAuth: true,
    isHidden: true,
    label: <FormattedMessage id="Guide" />,
  },
  fund: {
    path: "/fund",
    component: Fund,
    isAuth: true,
    isHidden: true
  },
  depositUSDT: {
    path: "/depositUSDT/:id",
    component: DepositUSDT,
    isAuth: true,
    isHidden: true
  },
  depositPIT: {
    path: "/depositPIT/:id",
    component: DepositPIT,
    isAuth: true,
    isHidden: true
  },
  depositBank: {
    path: "/depositBank/:id",
    component: DepositBank,
    isAuth: true,
    isHidden: true
  },
  withdrawUSDT: {
    path: "/withdrawUSDT",
    component: WithdrawUSDT,
    isAuth: true,
    isHidden: true
  },
  withdrawBank: {
    path: "/withdrawBank",
    component: WithdrawBank,
    isAuth: true,
    isHidden: true
  },
  withdrawPIT: {
    path: "/withdrawPIT",
    component: WithdrawPIT,
    isAuth: true,
    isHidden: true
  },
  transferPit: {
    path: "/transferPIT",
    component: TransferPit,
    isAuth: true,
    isHidden: true
  },
  transferCommission: {
    path: "/transferCommission",
    component: TransferCommission,
    isAuth: true,
    isHidden: true
  },
  USDTWallet: {
    path: "/USDTWallet",
    component: USDTWallet,
    isAuth: true,
    isHidden: true
  },
  PITWallet: {
    path: "/PITWallet",
    component: PITWallet,
    isAuth: true,
    isHidden: true
  },
  RoseWallet: {
    path: "/RoseWallet",
    component: RoseWallet,
    isAuth: true,
    isHidden: true
  },
  USDTWalletbyId: {
    path: "/USDTWallet/:id",
    component: DetailUSDTWallet,
    isAuth: true,
    isHidden: true
  },
  PITWalletbyId: {
    path: "/PITWallet/:id",
    component: DetailPITWallet,
    isAuth: true,
    isHidden: true
  },
  USDTWalletByIdDraw: {
    path: "/USDTWalletDraw/:id",
    component: DetailDraw,
    isAuth: true,
    isHidden: true
  },
  PITWalletByIdDraw: {
    path: "/PITWalletDraw/:id",
    component: DetailDrawPIT,
    isAuth: true,
    isHidden: true
  },
  PITWalletByIdTransaction: {
    path: "/PITWalletTransaction/:id",
    component: DetailTransactionPIT,
    isAuth: true,
    isHidden: true
  },
  RoseWalletById: {
    path: "/RoseWallet/:id",
    component: RoseWalletById,
    isAuth: true,
    isHidden: true
  },
  shopHistoryRentMachine: {
    path: "/shopHistoryRentMachine",
    component: ShopHistoryRentMachine,
    isAuth: true,
    isHidden: true
  },
  factoryHistory: {
    path: "/factoryHistory",
    component: FactoryHistory,
    isAuth: true,
    isHidden: true
  },
  factoryTransfer: {
    path: "/factoryTransfer",
    component: FactoryTransfer,
    isAuth: true,
    isHidden: true
  },
  errorBank: {
    path: "/errorBank",
    component: ErrorBank,
    isAuth: true,
    isHidden: true
  },
  overnightProfit: {
    path: "/overnightProfit",
    component: OvernightProfit,
    isAuth: true,
    isHidden: true
  },
  detailMachineById: {
    path: "/detailMachineById/:id",
    component: DetailMachineShopRoute,
    isAuth: true,
    isHidden: true
  },
  detailFactoryById: {
    path: "/detailFactoryById/:id",
    component: DetailFactoryRoute,
    isAuth: true,
    isHidden: true
  }
};
routes.home = {
  path: '/',
  component: Home,
  isAuth: true,
  label: 'Trang chủ',
  showFooterMobile: true
}

function App() {
  const isUserLoggedIn = useSelector((state) => (state.member ? state.member.isUserLoggedIn : false))
  // const isInitLoad = useSelector((state) =>
  //   state.member ? state.member.isInitLoad : true
  // );
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    document.body.setAttribute('data-theme', THEME)
  }, [])

  return (
    isLoading
      ? <Splash setIsLoading={setIsLoading}/>
      : <Router>
        <Suspense
          fallback={
            <div>
              <FormattedMessage id="LOADING_TEXT" defaultMessage={'Đang tải dữ liệu...'} />
            </div>
          }>
          <Switch>
            {Object.keys(routes).map((key, index) => {
              if (isUserLoggedIn && routes[key].isAuth) {
                return (
                  <Route
                    key={index}
                    exact
                    path={routes[key].path}
                    component={(props) => (
                      <Layout
                        {...props}
                        isAuth={routes[key].isAuth}
                        Component={routes[key].component}
                        showFooterMobile={routes[key].showFooterMobile}
                        className={`${routes[key].className || 'HOME'}`}
                      />
                    )}
                  />
                )
              } else if (!routes[key].isAuth) {
                return (
                  <Route
                    key={index}
                    exact
                    path={routes[key].path}
                    component={(props) => (
                      <Layout
                        {...props}
                        isAuth={routes[key].isAuth}
                        Component={routes[key].component}
                        showFooterMobile={routes[key].showFooterMobile}
                        className={routes[key].isHeader ? 'HOME' : 'LOGIN'}
                      />
                    )}
                  />
                )
              }
            })}
          </Switch>
        </Suspense>
      </Router>
  )
}

export default App
