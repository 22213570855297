import useInterval from "../../hooks/useInterval";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CustomerService from "../../services/customerMessage";
import { IconBell } from "assets/icons/iconBasic";
import { routes } from "App";

export default function Notification() {
  const history = useHistory();
  const [notificationCount, setNotificationCount] = useState(null);
  if (notificationCount > 99) {
    setNotificationCount("99+");
  }
  const filter = {};
  useEffect(() => {
    CustomerService.getUnreadNotificationCount({ filter }).then((result) => {
      const { isSuccess, data } = result;
      if (isSuccess) {
        setNotificationCount(data.total);
      }
    });
  }, []);
  useInterval(() => {
    CustomerService.getUnreadNotificationCount({ filter }).then((result) => {
      const { isSuccess, data } = result;
      if (isSuccess) {
        setNotificationCount(data.total);
      }
    });
  }, 60000);
  return (
    <div className="position-relative" role="button">
      <IconBell />
      {notificationCount && notificationCount !== 0 ? (
        <p
          className="rounded-circle d-flex align-items-center justify-content-center"
          style={{
            width: "16px",
            height: "16px",
            position: "absolute",
            top: "-50%",
            right: "-50%",
            padding: "2px",
            fontSize: "8px",
            fontWeight: "var(--font_weight_primary)",
            background: "var(--color_danger)",
          }}
        >
          {notificationCount}
        </p>
      ) : (
        <></>
      )}
    </div>
  );
}
