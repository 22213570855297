/* eslint-disable react-hooks/exhaustive-deps */
import { handleGetAppConfigurationSuccess } from "actions/appAction";
import { BANNER_CATEGORIES } from "constants/config";
import { APP_LANGUAGE } from "constants/languageConstant";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import bannerService from "services/bannerService";
import SystemConfiguration from "services/systemConfiguration";
export const SystemContext = createContext(null);

export function SystemProvider({ children }) {
  const user = useSelector((state) => state.member);
  const system = useSelector((state) => state.app?.config);
  const appUserId = useSelector((state) =>
    state.member ? state.member.appUserId : null,
  );
  const dispatch = useDispatch();

  const facPrice = useMemo(() => {
    let price = system?.exchangeRateCoin1;
    let _packageCurrentStage = system?.packageCurrentStage;
    if (_packageCurrentStage === 1) {
      price = system.exchangeRateCoin1;
    } else if (_packageCurrentStage === 2) {
      price = system.exchangeRateCoin2;
    } else if (_packageCurrentStage === 3) {
      price = system.exchangeRateCoin3;
    } else if (_packageCurrentStage === 4) {
      price = system.exchangeRateCoin4;
    } else if (_packageCurrentStage === 5) {
      price = system.exchangeRateCoin5;
    }
    return price;
  }, [system]);

  function handleGetSAppConfigs() {
    SystemConfiguration.systemConfigurationFind().then((result) => {
      const { error, data } = result;
      if (!error) {
        dispatch(handleGetAppConfigurationSuccess(data));
      }
    });
  }

  useEffect(() => {
    handleGetSAppConfigs();
  }, [appUserId]);

  //-----------Phần banner
  const [banners, setBanners] = useState([]);
  function handleGetBannerList() {
    bannerService
      .getBannerList({
        filter: {
          bannersCategory: BANNER_CATEGORIES.HOMEPAGE,
          bannersLanguage: user?.appUserLanguageId || APP_LANGUAGE.en.id,
          isHidden: 0,
        },
        skip: 0,
        limit: 5
      })
      .then((result) => {
        const { error, data } = result;
        if (!error) {
          setBanners(data?.data);
        }
      });
  }
  useEffect(() => {
    handleGetBannerList();
  }, [user?.appUserLanguageId]);
  //-------------

  //-----------MetaData
  const [metaData, setMetaData] = useState(undefined);
  useEffect(() => {
    appUserId && user.token && SystemConfiguration.GetMetaData()
      .then((result) => {
        setMetaData(result?.data);
      });
  }, [appUserId]);
  //-------------   

  return (
    <SystemContext.Provider value={{ system, handleGetSAppConfigs, facPrice, banners, metaData }}>
      {children}
    </SystemContext.Provider>
  );
}

export function useSystem() {
  return useContext(SystemContext);
}
