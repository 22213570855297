import BasicAlertPopup from "components/Basic/BasicAlertPopup/BasicAlertPopup";
import { VALUE_REGISTER } from "constants/config";
import React from "react";

const isReadyToSubmitRegistration=(dataToAction)=> {
  if (
    // !dataToAction?.username ||
    !dataToAction.newPassword ||
    !dataToAction.repassword ||
    !dataToAction.email ||
    !dataToAction.verification
  ) {
    return true
  }
  return false
};
const isReadyToSendRegistrationCode =(otpCountdown,dataToAction,errorsMessage)=> {
  if (
    otpCountdown !== 0 || 
    !dataToAction.email || 
    // !dataToAction?.username ||
    !dataToAction?.newPassword ||
    !dataToAction?.repassword ||
    errorsMessage.email ||
    // errorsMessage?.username ||
    errorsMessage?.newPassword ||
    errorsMessage?.repassword
  ) {
    return true
  }
  return false
}
const isUserNameExceedMaxLength =(value)=> {
  if (value && value.length >VALUE_REGISTER.MAX) {
    return true
  }
  return false
}
  // function apiCheckUsernameDuplicate(data) {
  //   if (!data.match(/^[a-zA-Z0-9]*$/)) {
  //     setErrorsMessage({
  //       ...errorsMessage,
  //       username: translate("accountUnavailable"),
  //     });
  //   } else {
  //     LoginService.checkUsernameDuplicate({ username: data }).then((result) => {
  //       const { isSuccess, error, message, isMaintain } = result;
  //       if (error !== 'DUPLICATE_DATA') {
  //         setErrorsMessage({
  //           ...errorsMessage,
  //           username: translate("accountUnavailable"),
  //         });
  //       } else {
  //         if (!isErrorUsername) {
  //           setErrorsMessage({
  //             ...errorsMessage,
  //             username: "",
  //           });
  //         }
  //       }
  //     });
  //   }
  // }
  // useEffect(() => {
  //   const timeout1 = setTimeout(() => {
  //     setCheckAPI(true);
  //   }, 500);
  //   return () => {
  //     clearTimeout(timeout1);
  //   };
  // }, [dataToAction.username]);

export { 
  isReadyToSubmitRegistration, 
  isReadyToSendRegistrationCode,
  isUserNameExceedMaxLength,
}