import React, { useEffect, useRef, useState } from "react";
import Loader from "./../../components/Loader";
import { Form, Input } from "antd";
import LoginService from "./../../services/loginService";

import "./forgotPass.scss";
import { injectIntl } from "react-intl";
import BasicAlertPopup from "components/Basic/BasicAlertPopup/BasicAlertPopup";
import { Header } from "components/Layout/Header/Header";
import BasicButton from "components/Basic/BasicButton/BasicButton";
import moment from "moment";
import { routes } from "App";
import { useDispatch } from "react-redux";
import PopupValidatorPassword from "components/Advanced/PopupValidatorPassword/PopupValidatorPassword";
import useCommonHook from "hooks/useCommonHook";
import { isReadyToSendRegistrationCode, isReadyToSubmitRegistration, isUserNameExceedMaxLength } from "./ForgotPassAction";

const TIME_COUNTDOWN = 300;

function ForgotPass(props) {
  const [isVisible, setIsVisible] = useState(false);
  const { history, location } = props;
  const search = location.search;
  const { translate } = useCommonHook();
  const params = new URLSearchParams(search);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [checkAPI, setCheckAPI] = useState(false);
  const [otpCountdown, setOtpCountdown] = useState(0);
  const idCountdown = useRef(null);
  const [isErrorUsername, setIsErrorUsername] = useState(false);
  const language = localStorage.getItem("lang");
  const [showPopup, setShowPopup] = useState(false);
  const nation=Number(location?.state?.nation)
  const [errorsMessage, setErrorsMessage] = useState({
    // username: "",
    newPassword: "",
    repassword: "",
    verification: "",
    email: "",
  });
  const [dataToAction, setDataToAction] = useState({
    // username: "",
    newPassword: "",
    repassword: "",
    verification: "",
    email: "",
  });

  function onFinish(values) {
    if (
      // !dataToAction.username ||
      !dataToAction.newPassword ||
      !dataToAction.repassword ||
      !dataToAction.verification ||
      !dataToAction.email
    ) {
      BasicAlertPopup({
        title: translate("forgotFail"),
        color: "danger",
        icon: "danger",
        content: translate("registerCheckValues"),
      });
    } else {
      checkRegexText(values)
      handleForgotPass(values);
    }
  }
  function handleSendOTP() {
    let value={
      email : dataToAction.email,
      // username : dataToAction.username,
    }
    const email = dataToAction.email;
    LoginService.sendEmailOTPForgotPass(value).then((res) => {
      const { isSuccess, error } = res;
      if (error) {
        handleCheckErrorOTP(error)
        clearTimeout(idCountdown.current);
        setOtpCountdown(0);
        return;
      } else {
        BasicAlertPopup({
        title: translate("sendOtpSuccess"),
        color: "success",
        icon: "success",
        showCloseButton: true,
      });
        return;
      }
    });
  }
  const handleGoToLoginPage = () => {
    history.push(routes.login.path);
  };

  const handleForgotPass = (values) => {
    if (
      // errorsMessage.username ||
      errorsMessage.newPassword ||
      errorsMessage.repassword ||
      errorsMessage.verification ||
      errorsMessage.email
    ) {
      return;
    } else {
      setIsVisible(true);

      const newData = {
        newPassword: values.newPassword,
        email: values.email,
        username: values.email,
        otpCode: values.verification,
      };
      LoginService.ForgotPass(newData).then((result) => {
        const { isSuccess, data, message, error } = result;
        setIsVisible(false);
        if (!isSuccess) {
          handlCheckErrorForgot(error)
        } else {
          BasicAlertPopup({
            title: translate("forgotSuccessTitle"),
            color: "success",
            icon: "success",
            content: translate("forgotSuccessSubtitle"),
            confirmButtonText: translate("ReLogin"),
            onConfirm: handleGoToLoginPage
          });
        }
      });
    }
  };
  const handlCheckErrorForgot=(error)=>{
    if(error === 'USER_NOT_FOUND') {
      setErrorsMessage({
        ...errorsMessage,
        username: translate("EMAIL_NOT_MATCH"),
      });
    }else if (error === 'OTP_FAILED') {
      BasicAlertPopup({
        title: translate("OTP_FAILED"),
        color: "danger",
        icon: "danger",
        content: translate("registerCheckValues"),
      });
    }else if (error === 'OTP_EXPIRED') {
      BasicAlertPopup({
        title: translate("OTP_EXPIRED"),
        color: "danger",
        icon: "danger",
        content: translate("registerCheckValues"),
      });
    }else if (error === 'UPDATE_FAILED') {
      BasicAlertPopup({
        title: translate("forgotFail"),
        color: "danger",
        icon: "danger",
        content: translate("registerCheckValues"),
      });
    }else if (error === 'UNKNOWN_ERROR') {
      BasicAlertPopup({
        title: translate("forgotFail"),
        color: "danger",
        icon: "danger",
        content: translate("registerCheckValues"),
      });
    }else {
      BasicAlertPopup({
        title: translate("forgotFail"),
        color: "danger",
        icon: "danger",
        content: translate("registerCheckValues"),
      });
    }
  }
  const errorPopup=(value)=>{
    BasicAlertPopup({
      title: translate(value),
      color: "danger",
      icon: "danger",
      content: translate("registerCheckValues"),
      showCloseButton:true,
    })
  }
  const handleCheckErrorOTP=(error)=>{
    if (error == 'EMAIL_NOT_MATCH') {
      setErrorsMessage({
        ...errorsMessage,
        email: translate("EMAIL_NOT_MATCH"),
      });
      return
    }
    if (error == 'USER_NOT_FOUND') {
      errorPopup("USER_NOT_FOUND")
      return
    }
    if (error == 'INVALID EMAIL') {
      setErrorsMessage({
        ...errorsMessage,
        email: translate("INVALID_EMAIL"),
      });
      return
    }
    errorPopup("SEND_OTP_MAIL_FAILED")
    return
  }


  function checkRegexPassText(values) {
    if (values.newPassword) {
      if (!values?.newPassword.match(/^(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,20}$/)) {
        errorsMessage.newPassword= translate("format_pass");
        return
      } 
      errorsMessage.newPassword= ''
    }
  }
  function checkRegexRePassText(values) {
    if (values.repassword) {
      if (values?.newPassword !== values?.repassword) {
        setErrorsMessage({
          ...errorsMessage,
          repassword: translate("confirm_new_password_incorrect"),
        });
      } else {
        setErrorsMessage({
          ...errorsMessage,
          repassword: "",
        });
      }
    }
  }
  function checkRegexEmailText(values,check) {
    if (values.email) {
      if (!values.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
        setErrorsMessage({
          ...errorsMessage,
          email: translate("error_email"),
        });
        return
      } 
      setErrorsMessage({
        ...errorsMessage,
        email: "",
      });
    }
    setErrorsMessage({
      ...errorsMessage,
      email: "",
    });
    if(check){
      handleSendOTP();
      setOtpCountdown(TIME_COUNTDOWN);
    }
  }
  function checkRegexVerificationText(values) {
    if (values.verification) {
      if (!values?.verification.match(/^[0-9]{6,}$/)) {
        setErrorsMessage({
          ...errorsMessage,
          verification: translate("error_verification"),
        });
        return
      } 
      setErrorsMessage({
        ...errorsMessage,
        verification: "",
      });
    }
  }
  const checkRegexText=(dataToAction)=>{
    setTimeout(() => {
      checkRegexPassText(dataToAction)
    }, 100);
  }
  useEffect(() => {
    checkRegexText(dataToAction);
  }, [dataToAction]);
  useEffect(() => {
    checkRegexText(dataToAction);
  }, [dataToAction]);
  // useEffect(() => {
  //   if (checkAPI)
  //     apiCheckUsernameDuplicate(dataToAction.username);
  // }, [checkAPI]);
  useEffect(() => {
    if (otpCountdown !== 0) {
      idCountdown.current = setTimeout(() => {
        setOtpCountdown(otpCountdown - 1);
      }, 1000);
    }
  }, [otpCountdown]);


  return (
    <div
      className="forgot"
    >
      <div className="forgot-header">
        <Header
          backgroundColor="transparent"
          colorItem="dark"
          showLanguage={true}
          className="leader_header"
        />
      </div>
      <div className="forgot-center">
        <div>
          <div className="forgot__title">{translate("forgotTitle")}</div>
        </div>
        <Form
          name="login"
          autoComplete="off"
          form={form}
          onFinish={(values) => {
            onFinish(values);
          }}
        >
          {/* <Form.Item name="username" label={translate("account")}>
            <Input
              className="input input-text-hidden inputUsername"
              placeholder={translate("inputAccount")}
              size="large"
              style={{
                width: "100%",
              }}
              onBlur={()=>setCheckAPI(true)}
              value={dataToAction.username}
              onChange={(e) => {
                let value = e.target.value;
                if (isUserNameExceedMaxLength(value)) {
                  value = dataToAction.username;
                }
                form.setFieldsValue({
                  username:value
                })
                setDataToAction({ ...dataToAction, username: value });
                setCheckAPI(false);
              }}
            />
            {errorsMessage.username ? (
              <p className="error-mess">{errorsMessage.username || " "}</p>
            ) : (
              <div style={{ opacity: 0,marginTop:"2px",height:'20px' }}>lỗi</div>
            )}
          </Form.Item> */}

          <Form.Item name="newPassword" label={translate("new_password")}>
            <Input.Password
              className="input input-text-hidden hiden-border tracker"
              type={"password"}
              placeholder={translate("inputPassword")}
              size="large"
              onBlur={() => setShowPopup(false)}
              onClick={() => setShowPopup(true)}
              onKeyDown={(e) => {
                [" "].includes(e.key) && e.preventDefault();
                setShowPopup(true);
              }}
              onChange={(e) =>{
                setDataToAction({ ...dataToAction, newPassword: e.target.value,repassword:'' })
                checkRegexPassText({ ...dataToAction, newPassword: e.target.value })
                checkRegexRePassText(dataToAction)
                form.setFieldsValue({
                  newPassword:e.target.value,
                  repassword:''
                })
                setErrorsMessage({
                  ...errorsMessage,
                  repassword:''
                })
              }}
            />
            {showPopup && (
              <PopupValidatorPassword
                translate={translate}
                className={"tracker-box"}
                value={dataToAction.newPassword}
              ></PopupValidatorPassword>
            )}
            {errorsMessage.newPassword ? (
              <p className="error-mess">{errorsMessage.newPassword || " "}</p>
            ) : (
              <div style={{ opacity: 0,marginTop:"2px",height:'20px' }}>lỗi</div>
            )}
          </Form.Item>

          <div className="forgotItem">
            <Form.Item
              name="repassword"
              label={translate("confirm_new_password")}
            >
              <Input.Password
                className="input input-text-hidden hiden-border"
                type={"password"}
                placeholder={translate("confirm_password")}
                size="large"
                value={dataToAction.repassword}
                onKeyDown={(e) => [" "].includes(e.key) && e.preventDefault()}
                onBlur={()=>checkRegexRePassText(dataToAction)}
                onChange={(e) =>{
                  setErrorsMessage({
                    ...errorsMessage,
                    repassword:''
                  })
                  setDataToAction({
                    ...dataToAction,
                    repassword: e.target.value,
                  })
                  checkRegexPassText(dataToAction)
                  form.setFieldsValue({
                    repassword:e.target.value
                  })
                }}
              />
              {errorsMessage.repassword ? (
                <p className="error-mess">{errorsMessage.repassword || " "}</p>
              ) : (
                <div style={{ opacity: 0,marginTop:"2px",height:'20px' }}>lỗi</div>
              )}
            </Form.Item>
          </div>
          <Form.Item name="email" label={translate("email")}>
            <Input
              className="input input-text-hidden hiden-border"
              controls={false}
              suffix={
                <span
                  className={`buttonConfirm text-light ${
                    isReadyToSendRegistrationCode(otpCountdown,dataToAction,errorsMessage) ? "isDisable" : ""
                  }`}
                  onClick={() => {
                    checkRegexEmailText(dataToAction,true);
                  }}
                >
                  {!otpCountdown && translate("send_code")}
                  {otpCountdown === 0
                    ? ""
                    : `${moment.utc(otpCountdown * 1000).format("mm:ss")}`}
                </span>
              }
              placeholder={translate("inputEmail")}
              value={dataToAction.email}
              onBlur={()=>checkRegexEmailText(dataToAction,false)}
              size="large"
              onChange={(e) => {
                setErrorsMessage({
                  ...errorsMessage,
                  email: "",
                });
                const cleanedValue = e.target.value;
                setOtpCountdown(0);
                clearTimeout(idCountdown.current);
                setDataToAction({ ...dataToAction, email: cleanedValue });
                form.setFieldsValue({
                  email:cleanedValue
                })
              }}
            />
            {errorsMessage.email ? (
              <p className="error-mess">{errorsMessage.email || " "}</p>
            ) : (
              <div style={{ opacity: 0,marginTop:"2px",height:'20px' }}>lỗi</div>
            )}
          </Form.Item>
          <Form.Item name="verification" label={translate("verification")}>
            <Input
              className="input input-text-hidden inputBasic"
              type="text"
              placeholder={translate("inputOTP")}
              value={dataToAction?.verification}
              onBlur={()=>checkRegexVerificationText(dataToAction)}
              onChange={(e) => {
                setErrorsMessage({
                  ...errorsMessage,
                  verification: "",
                });
                const { value: inputValue } = e.target;
                const reg = /^\d*(\d*)?$/;
                let value=e.target.value
                if (reg.test(inputValue) || inputValue === '') {
                  if (e.target.value.length <= 6) {
                    setDataToAction({
                      ...dataToAction,
                      verification: e.target.value,
                    });
                    form.setFieldsValue({
                      verification:e.target.value
                    })
                  }
                }
              }}
            />
            {errorsMessage.verification ? (
              <p className="error-mess">{errorsMessage.verification || " "}</p>
            ) : (
              <div style={{ opacity: 0,marginTop:"2px",height:'20px' }}>lỗi</div>
            )}
          </Form.Item>

          <BasicButton
            className={`forgot-btn w-100 ${isReadyToSubmitRegistration(dataToAction) ? 'isDisable' : ''}`}
            size="large"
            htmlType="submit"
          >
            {translate("confirm")}
          </BasicButton>
        </Form>
      </div>
      {isVisible ? <Loader /> : null}
    </div>
  );
}
export default injectIntl(ForgotPass);
