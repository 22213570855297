import React from "react";
import style from "./index.module.scss";

const BasicButton = ({
  children,
  className = "",
  outlined = false,
  color = "primary", // colors = ["primary", "secondary", "danger", "success", "info"];
  width = "100%",
  onClick,
  ...rest
}) => {
  const basicClassName = `background_${color}${outlined ? "_outlined" : "_gradiant"}`;
  return (
    <div style={{ width: width }}>
      <button
        className={`${style["BasicButton"]} ${basicClassName} ${className}`}
        onClick={onClick}
        {...rest}
      >
        {children}
      </button>
    </div>
  );
};

export default BasicButton;
