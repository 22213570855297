import { Header } from "components/Layout/Header/Header";
import useCommonHook from "hooks/useCommonHook";
import React, { useEffect, useState } from "react"
import BasicInput from "components/Basic/BasicInput/BasicInput";
import { useUser } from "context/UserContext";
import SendCode from "components/Advanced/SendCode/SendCode";
import classNames from "classnames";
import BasicButton from "components/Basic/BasicButton/BasicButton";
import BasicAlertPopup from "components/Basic/BasicAlertPopup/BasicAlertPopup";
import PaymentMethod from "services/paymentMethod";
import { PAYMENT_METHOD_TYPE } from "hooks/usePaymentMethod";
import { errorOtpPaymentMethod, errorPaymentMethod } from "./PaymentMethodInsertUsdt";
import usePaymentMethodInsert from "../actions/useMethodInsertAction";
import { useHistory, useParams } from "react-router-dom";
import { usePaymentMethods } from "hooks/usePaymentMethod";
import { PAYMENT_UNIT } from "hooks/usePaymentMethod";
import { MAX_PAYMENT_METHOD_OF_USER } from "hooks/usePaymentMethod";
import { routes } from "App";
import BasicSelect from "components/Basic/BasicSelect/BasicSelect";
import { useSystem } from "context/SystemContext";

const PaymentMethodInsertBank = () => {
  const history = useHistory()
  const { translate } = useCommonHook();
  const { type } = useParams();
  const { user } = useUser();
  const { system } = useSystem();
  const { metaData } = useSystem()
  const paymentUnit = metaData?.SYSTEM_NATIONAL && PAYMENT_UNIT?.[Object.values(metaData?.SYSTEM_NATIONAL)?.find(e => e.id === user?.appUserNation)?.label]
  const nationText = metaData?.SYSTEM_NATIONAL && translate(Object.values(metaData?.SYSTEM_NATIONAL)?.find(e => e.id === user?.appUserNation)?.label)
  console.log(nationText);
  const [loading, setLoading] = useState(false)
  const {
    handleFormatHideAccountName,
    handleFormatHideEmail,
    handleGetOnlyString,
    isEnoughInfoUser
  } = usePaymentMethodInsert()
  const firstName = user.firstName ? " " + user.firstName : ""
  const middleName = user.middleName ? " " + user.middleName : ""
  const lastName = user.lastName ? user.lastName : ""
  const [dataToAction, setDataToAction] = useState({
    paymentMethodReceiverName: lastName + middleName + firstName,
  });
  const [dataListBank, setDataListBank] = useState([])
  const [checkDisabled, setCheckDisabled] = useState({
    sendCode: true,
    submit: true,
  });
  const [errorOTP, setErrorOTP] = useState(undefined);
  const { data, showPopupService } = usePaymentMethods(type);

  useEffect(() => {
    if (
      dataToAction?.paymentMethodIdentityNumber
      && user.email && !errorOTP
      && dataToAction?.paymentMethodReferName
    ) {
      setCheckDisabled({
        submit: true,
        sendCode: false,
      });
      if (dataToAction?.otp) {
        setCheckDisabled({
          sendCode: false,
          submit: false,
        });
      }
    } else {
      setCheckDisabled({
        sendCode: true,
        submit: true,
      });
    }

  }, [dataToAction, errorOTP]);

  function _handleInserPaymentMethod() {
    if (data?.length >= MAX_PAYMENT_METHOD_OF_USER) {
      return showPopupService()
    }

    const params = {
      data: {
        paymentMethodName: dataToAction?.paymentMethodName,
        paymentMethodType: PAYMENT_METHOD_TYPE[type],
        paymentMethodUnit: paymentUnit,
        paymentMethodIdentityNumber: dataToAction?.paymentMethodIdentityNumber,
        paymentMethodReferName: dataToAction?.paymentMethodReferName,
        paymentMethodReceiverName: dataToAction?.paymentMethodReceiverName,
        paymentMethodImageUrl: dataToAction?.paymentMethodImageUrl,
        paymentMethodNationId: user?.appUserNation,
      },
      otp: dataToAction?.otp,
    };

    setLoading(true)
    PaymentMethod.insertPaymentMethod(params).then((response) => {
      setLoading(false)
      const { isSuccess, error } = response;
      if (isSuccess) {
        BasicAlertPopup({
          title: translate("Confirmed_successfully"),
          content: translate("Your_bank_account_has_been_linked"),
          color: "success",
          icon: "success",
        });
        history.goBack()
      } else {
        if (error === errorOtpPaymentMethod?.[error]) {
          setErrorOTP(translate(error));
        }
        // errorPaymentMethod?.[error] sẽ kiểm tra error có trong errorPaymentMethod hay không để thông báo lỗi
        else if (errorPaymentMethod?.[error]) {
          BasicAlertPopup({
            title: translate(`Link_failed`),
            color: "danger",
            icon: "danger",
            content: translate(errorPaymentMethod?.[error]),
          });
        } else {
          BasicAlertPopup({
            title: translate(`Link_failed`),
            color: "danger",
            icon: "danger",
            content: translate(`Please_check_the_information_again`),
          });
        }
      }
    });
  }

  useEffect(() => {
    PaymentMethod.getPublicBankList({
      filter: {
        paymentMethodNationId: user.appUserNation || 1,
      }
    }).then((response) => {
      const data = response?.data?.map((item) => ({
        value: item?.code,
        label: <div>
          <img src={item?.logo} alt="" height={20} width={20}
            style={{
              border: "1px solid var(--border_card_color)",
              marginRight: "6px",
              objectFit: "cover",
            }}
          />
          <span>{item?.shortName}</span>
        </div>,
        logo: item?.logo,
        shortName: item?.shortName,
        name: item?.name
      }))
      setDataListBank(data || []);
    });
  }, [user.appUserNation]);

  return (
    <div className="PaymentMethodInsert">
      <Header title={translate("Add_bank_account")}></Header>
      <p className="PaymentMethodInsert_title">{translate("Information_at_the_bank")}</p>
      <div className="PaymentMethodInsert_content">
        <div>
          <p className="PaymentMethodInsert_content_label">
            {translate("Account_name")}
          </p>
          <BasicInput
            className={isEnoughInfoUser ? "" : "PaymentMethodInsert_errorBorder"}
            value={handleFormatHideAccountName(dataToAction?.paymentMethodReceiverName?.trim())}
            disabled={true}
          />
          {
            !isEnoughInfoUser &&
            <p className="PaymentMethodInsert_errorOTP">{translate("Please_complete_your_account_information_in_my_section")}</p>
          }
        </div>

        <div>
          <p className="PaymentMethodInsert_content_label">
            {translate("label_bank")}
          </p>
          <BasicSelect
            showSearch
            placeHolderSearch={translate("Enter_the_bank_name")}
            placeholder={translate("Select_bank")}
            value={dataListBank.find(
              (item) => item.value === dataToAction?.paymentMethodReferName)
            }
            options={dataListBank}
            onChange={(value) => {
              setDataToAction({
                ...dataToAction,
                paymentMethodName: dataListBank.find(
                  (item) => item.value === value
                )?.shortName,
                paymentMethodReferName: value,
                paymentMethodImageUrl: dataListBank.find(
                  (item) => item.value === value
                )?.logo,
              });
            }}
            filterOption={(input, option) =>
              option.value.toLowerCase().includes(input.toLowerCase())
              || option.shortName.toLowerCase().includes(input.toLowerCase())
              || option.name.toLowerCase().includes(input.toLowerCase())
            }
            onClick={() =>
              dataListBank.length === 0 &&
              BasicAlertPopup({
                content: translate("Currently_the_system_linking_banks_in_country_is_under_maintenance_Please_contact_Customer_Service_for_support",
                  { country: nationText }
                ),
                color: "info",
                icon: "service",
                confirmButtonText: translate("contact"),
                showCloseButton: true,
                onConfirm: () => {
                  window.open(system?.supportChatUrlEN, "_blank");
                },
              })
            }
          />
        </div>
        <div>
          <p className="PaymentMethodInsert_content_label">
            {translate("account_number")}
          </p>
          <BasicInput
            placeholder={translate("Enter_the_bank_account_number")}
            value={dataToAction?.paymentMethodIdentityNumber}
            onChange={(e) => {
              setDataToAction({
                ...dataToAction,
                paymentMethodIdentityNumber: handleGetOnlyString(e.target.value, dataToAction?.paymentMethodIdentityNumber),
              });
            }}
          />
          <p className="PaymentMethodInsert_bank_note">{translate("Note_setting_account_bank")}</p>
        </div>
        <div>
          <p className="PaymentMethodInsert_content_label">Email</p>
          <BasicInput
            value={handleFormatHideEmail(user.email)}
            disabled={true}
            suffix={
              <SendCode disabled={checkDisabled.sendCode} email={user.email} />
            }
          />
        </div>
        <div>
          <p className="PaymentMethodInsert_content_label">
            {translate("verification")}
          </p>
          <BasicInput
            className={errorOTP ? "PaymentMethodInsert_errorBorder" : ""}
            value={dataToAction?.otp}
            placeholder={translate("inputOTP")}
            onChange={(e) => {
              if (e.target.value.length <= 6) {
                setDataToAction({
                  ...dataToAction,
                  otp: handleGetOnlyString(e.target.value, dataToAction?.otp),
                });
                setErrorOTP(undefined);
              }
            }}
          />
          <p className="PaymentMethodInsert_errorOTP">{errorOTP}</p>
        </div>

        <BasicButton
          className={classNames({ isDisable: checkDisabled.submit || loading })}
          onClick={() => {
            if (!isEnoughInfoUser) {
              BasicAlertPopup({
                title: translate("Fail"),
                content: translate("Please_complete_your_account_information_before_updating_your_bank_information"),
                color: "danger",
                icon: "danger",
                confirmButtonText: translate("Go"),
                showCloseButton: true,
                onConfirm: () => {
                  history.push(routes?.accountInfo?.path);
                },
              });
            } else {
              _handleInserPaymentMethod();
            }
          }}
        >
          {translate("Confirm")}
        </BasicButton>
      </div>
    </div>
  );
};
export default PaymentMethodInsertBank;
