import React from "react";
import Authenticator from "./authenticator";
import { useModal } from "context/ModalContext";
import { useUser } from "context/UserContext";
import "./index.scss";
import useCommonHook from "hooks/useCommonHook";
import { Header } from "components/Layout/Header/Header";
import { IconGoogleAuth } from "assets/icons/iconBasic";
import { IconDownLoad } from "assets/icons/iconBasic";
import BasicButton from "components/Basic/BasicButton/BasicButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const Two2Fa = (props) => {
  const { user, refresh } = useUser();
  const { translate } = useCommonHook();
  const history = useHistory()
  const modal = useModal();
  const handleClickBtn = (val) => {
    modal.show({
      content: <Authenticator history={history} />,
      hiddenHeader:true
    });
  };
  const handleDownloadApp=()=>{
    let result = navigator.userAgent.toLowerCase();
    let android = result.indexOf("android") > -1;
    if(android){
      window.open('https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2')
    }else{
      window.open('https://apps.apple.com/vn/app/google-authenticator/id388497605')
    }
  }
  return (
    <>
      <Header title={translate("GoogleAuth")}
        iconsLeft={{
          icon: "back",
          onClick: () => {
            history.push('/management/profile')
          }
        }} />
      <section className="f2a">
        <div className="f2a-top">
          <div className="f2a-top-icon">
            <IconGoogleAuth></IconGoogleAuth>
          </div>
          <div className="f2a-top-text">
            {translate("GoogleAuthNote")}
          </div>
        </div>
        <div className="f2a-center" onClick={()=>handleDownloadApp()}>
          <img className='' src="/assets/images/googleAuth.png" alt="" />
          <div>
            {translate('DownloadGoogleAuth')}
          </div>
          <div>
          <IconDownLoad></IconDownLoad>
          </div>
        </div>
        <BasicButton
          className={`w-100`}
          size="large"
          onClick={handleClickBtn}
        >
          {translate("Active")}
        </BasicButton>
      </section>
    </>
  );
};

export default Two2Fa;
