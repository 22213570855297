import { IconCamera } from "assets/icons/iconBasic";
import { IconCrownLine } from "assets/icons/iconBasic";
import classNames from "classnames";
import { userFullName } from "context/UserContext";
import { userLevel } from "context/UserContext";
import { userAvatar } from "context/UserContext";
import React from "react";

export default function UserAvatar({
  user,
  vertical,
  horizontal,
  dark,
  onClick,
}) {
  return (
    <div
      className={classNames(
        "d-flex",
        "align-items-center",
        "position-relative",
        {
          "flex-column": vertical,
          "justify-content-center": vertical,
        },
      )}
    >
      <div className=" pointer avatar-img" onClick={onClick}>  
        <img
          className={classNames("img-rounded", {
            "img-rounded--vertical": vertical,
            pointer: !!onClick,
          })}
          src={userAvatar(user)}
          alt=""
          onClick={onClick}
        />
        <div className="select-avatar">
          <IconCamera></IconCamera>
        </div>
      </div>
      <div
        className={classNames("px-2 position-relative", {
          "mt-2": vertical,
          "text-center": vertical,
        })}
      >
        <p
          className={classNames( "text-center", {
            "mt-1": vertical,
            h6: vertical,
            "text-light": vertical && !dark,
            "text-dark": dark,
          })}
          style={{fontSize:'var(--font_size_text_regular)'}}
        >
          {user.companyName}
        </p>
        <div style={{background:'var(--color_dark_gradiant)',borderRadius:'20px',padding:'0 8px 0 6px',height:'16px'}} className="d-inline-flex align-items-center text-center">
          <IconCrownLine className="text-secondary me-1 avatar-icon" />
          <span className="text-white" style={{fontSize:'var(--font_size_text_small)',lineHeight:'14px'}}>
            {user.appUserMembershipTitle || "Level 0"}
          </span>
        </div>
      </div>
    </div>
  );
}
