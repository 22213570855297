import React from "react";
import "./index.scss";
import { Button } from "antd";
import { routes } from "App";
import { useHistory } from "react-router-dom";
import BasicButton from "components/Basic/BasicButton/BasicButton";
import { APP_LANGUAGE } from "constants/languageConstant";
import { IconArrowLeft } from "assets/icons/iconBasic";
import useCommonHook from "hooks/useCommonHook";
import IconChatService from "components/Advanced/IconChatService/IconChatService";

export default function WelcomePage() {
  const history = useHistory();
  const { translate } = useCommonHook();

  const handleShowLang = () => {
    let currentLanguageKey = window.localStorage.getItem("lang") || "en";

    return (
      <div
        className="lang-item"
        onClick={() => history.push(routes.settingLanguage.path)}
      >
        <div className="lang-img">{APP_LANGUAGE[currentLanguageKey].icon}</div>
        <div
          className="text-light"
          style={{ fontSize: "var(--font_size_text_small)" }}
        >
          {APP_LANGUAGE[currentLanguageKey].label}
        </div>
      </div>
    );
  };
  return (
    <div className="init-page">
      <div className="splash-top">
        <div>
          <img
            className="splash-img-top"
            src="/assets/images/Splash_bg_top.png"
            alt=""
          />
        </div>
        <div className="splash-header">
          <div
            className="back-btn text-light"
            onClick={() => {
              window.location.replace(process.env.REACT_APP_LANDING_PAGE_URL);
            }}
          >
            <IconArrowLeft style={{ fontSize: "20px" }} />
          </div>
          <div className="splash-lang">{handleShowLang()}</div>
        </div>
        <div></div>
      </div>
      <div className="splash-body">
        <div>
          <img
            className="splash-img-star"
            src="/assets/images/splash/star1.png"
            alt=""
          />
        </div>
        <div className="splash-main">
          <div className="splash-title">{translate("welcome_splash")}</div>
          <img className="splash-img" src="logoWithText.png" alt="" />
          <div>
            <BasicButton
              onClick={() => history.push(routes.login.path)}
              className="splash-btn btn-login"
              size="large"
            >
              {translate("login")}
            </BasicButton>
            <Button
              onClick={() => history.push(routes.nation.path)}
              className="btn-transparent splash-btn"
              size="large"
            >
              {translate("registerNow")}
            </Button>
          </div>
        </div>
      </div>
      <div className="splash-bottom">
        <div>
          <img
            className="splash-img-bottom"
            src="/assets/images/Splash_bg_bottom.png"
            alt=""
          />
        </div>
        <div></div>
      </div>
      <IconChatService />
    </div>
  );
}
