import React, { useEffect } from "react";
import { connect } from "react-redux";
import "./index.scss";
import Footer from "components/Layout/Footer/Footer";
import classNames from "classnames";

function LayoutPage(props) {
  const { showFooterMobile, Component, className = "" } = props;

  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      const headerId = document.getElementById("header-sticky");
      if (headerId && headerId.classList) {
        if (window.pageYOffset > 0) {
          headerId.classList.toggle("sticky");
        } else {
          headerId.classList.remove("sticky");
        }
      }
    });
  }, []);

  useEffect(() => {
    if (props.isAuth) {
      // document.getElementById('root').style.height = 'calc(100vh - 120px)'
    } else {
      document.getElementById("root").style.height = "100vh";
    }
  }, [props, props.isAuth]);

  useEffect(() => {
    const bodyId = document.getElementById("body-root");
    if (bodyId) {
      bodyId.classList.add(className || "");
    }
  }, [className]);

  return (
    <div className="layout">
      <div
        className={classNames({
          paddingFooter: showFooterMobile,
        })}
      >
        <Component {...props} />
      </div>
      {showFooterMobile && <Footer />}
    </div>
  );
}

const mapStateToProps = (state) => ({
  member: state.member || {},
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutPage);
