import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { useModal } from "context/ModalContext";
import { Button } from "antd";
import { HOST } from "constants/url";
import { useUser } from "context/UserContext";
import ConfirmAuth from "./confirmAuth";
import "./index.scss";
import { Header } from "components/Layout/Header/Header";
import { CopyIcon } from "assets/icons";
import useCommonHook from "hooks/useCommonHook";
import { IconWarning } from "assets/icons/iconBasic";
import BasicButton from "components/Basic/BasicButton/BasicButton";

const Authenticatior = (props) => {
  const { user } = useUser();
  const [authRdoState, setAuthRdoState] = useState([]);
  const { copyToClipboard } = useCommonHook();
  const modal = useModal();
  // useIntl template
  const intl = useIntl();

  const translate = useCallback(
    (id) => {
      return intl.formatMessage({ id });
    },
    [intl],
  );
  const handleClickBtn = (val) => {
    if (authRdoState) {
      modal.show({
        content: <ConfirmAuth history={props.history} />,
        transparent: true,
        hiddenHeader:true
      });
    } else {
    }
  };
  return (
    <>
      <Header 
        title={translate("AuthenLink")}
        iconsLeft={{
          icon: "back",
          onClick: () => {
            modal.back();
          }
        }}
      />
      <section className="authenticatior">
        <div className="authenticatior-item">
          <div>
            <div className="authen-step">01.</div>
            <div>{translate("CoppyAuthenCode")}</div>
          </div>
          <div className="mt-3">
            <div
              style={{
                backgroundColor: "var(--background_color_active)",
                paddingBottom: "10px",
                paddingTop: "10px",
                marginBottom:'12px',
                borderRadius: "5px",
              }}
              className="mt-2 d-flex justify-content-between align-items-center"
            >
              <div className={"authen_card_text"}>
                {user?.twoFACode}
              </div>
              <div
              style={{
                paddingRight:'12px'
              }}
                onClick={() => {
                  copyToClipboard(user?.twoFACode || "");
                }}
              >
                <CopyIcon />
              </div>
            </div>
          </div>
          <div className="authen-QR">
            <img
              src={`${HOST}AppUsers/get2FACode?appUserId=${user.appUserId}`}
              alt="qr-code"
            />
          </div>
        </div>
        <div className="authenticatior-item">
          <div>
            <div className="authen-step">02.</div>
            <div>{translate("CoppyAuthenCode2")}</div>
          </div>
          <div className="authen-step2">
            <img
              src={`/assets/images/step2-2fa.png`}
              alt="qr-code"
            />
          </div>
        </div>
        <div className="authenticatior-item">
          <div>
            <div className="authen-step">03.</div>
            <div>{translate("CoppyAuthenCode3")}</div>
          </div>
        </div>
        <div style={{background:"var(--background_color_primary)",paddingBottom:'20px'}}>
          <div className="warning-addtional">
            <div>
              <IconWarning></IconWarning>
            </div>
            <div className="warning-addtional-text">
              <div>
                {translate("CoppyAuthenCode4")}
              </div>
            </div>
          </div>
        </div>
        <div className="">
        <BasicButton
          className={`w-100 authen-btn`}
          size="large"
          onClick={handleClickBtn}
        >
          {translate("continute")}
        </BasicButton>
        </div>
      </section>
    </>
  );
};

export default Authenticatior;
