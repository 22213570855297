import React from "react";
import "./BasicSegmented.scss";
import { Segmented } from "antd";

const BasicSegmented = ({
  backgroudColor = "var(--color_primary_gradiant)", // truyền mã màu vào từ variables
  textColor = "var(--text_color_primary_in_background)", // truyền mã màu vào từ variables
  activeColor = "var(--color_primary)", // truyền mã màu vào từ variables
  backgroudAciveColor, // truyền mã màu vào từ variables
  block = true,
  disabled = false,
  onChange,
  options,
  value,
}) => {
  const styles = `.BasicSegmented{
    .BasicSegmented_background{
      background:${backgroudColor};
    }
    .ant-segmented{
      .ant-segmented-item{
        color:${textColor};
      }
      .ant-segmented-item-selected{
        color:${activeColor};
      }
      .ant-segmented-thumb, .ant-segmented-item-selected{
        background:${backgroudAciveColor};
        &::before{
          background:${activeColor};
        }
      }
    }
  }`;
  return (
    <div className="BasicSegmented">
      <style>{styles}</style>
      <div className="BasicSegmented_background"></div>
      <Segmented
        size="large"
        options={options}
        value={value}
        onChange={onChange}
        disabled={disabled}
        block={block}
      />
    </div>
  );
};

export default BasicSegmented;
