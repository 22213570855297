import { message } from "antd";
import { useCallback } from "react";
import { useIntl } from "react-intl";

export default function useCommonHook() {
  const intl = useIntl();
  const translate = useCallback(
    (id, options = {}) => intl.formatMessage({ id }, options),
    [intl],
  );

  function copyToClipboard(text, messageNotify = null) {
    var selected = false;
    var el = document.createElement("textarea");
    el.value = text;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    if (document.getSelection().rangeCount > 0) {
      selected = document.getSelection().getRangeAt(0);
    }
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
    }
    message.success(messageNotify || translate("Copy_successfully"));
  }

  return {
    copyToClipboard,
    translate,
  };
}
