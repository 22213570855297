import React, { useEffect } from "react"
import "./index.scss"
import useCommonHook from "hooks/useCommonHook"
import { APP_IMAGES } from "assets/images/AppImages";

export function Splash(props) {
  const { translate } = useCommonHook()

  useEffect(() => {
    const images = Object.values(APP_IMAGES)
    
    let loadedImages = 0;

    const loadImage = (imageSrc) => {
      const image = new Image();
      image.src = imageSrc;
      image.onload = () => {
        loadedImages++;
        if (loadedImages === images.length) {
          setTimeout(() => {
            props?.setIsLoading &&  props.setIsLoading(false);
          }, 1000);
        }
      };
    };

    images.forEach(image => loadImage(image));

    // Clean up function
    return () => {
      // Cancel any pending image loads if component unmounts before images load
      loadedImages = images.length;
    };
  }, []);

  return (
    <div className="splash max_width_screen_project">
      <div className="splashContent">
        <img src="/logo.png" alt="" width={100} height={100} />
        <p>{translate("LOADING_TEXT")}</p>
      </div>
    </div>
  )
}