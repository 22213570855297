import React from "react";
import { LanguageCambodian } from "assets/icons/iconLanguage";
import { LanguageChinese } from "assets/icons/iconLanguage";
import { LanguageEnglish } from "assets/icons/iconLanguage";
import { LanguageKorean } from "assets/icons/iconLanguage";
import { LanguageMalaysian } from "assets/icons/iconLanguage";
import { LanguageThailand } from "assets/icons/iconLanguage";
import { LanguageVietnamese } from "assets/icons/iconLanguage";
import { LanguageIndonesia } from "assets/icons/iconLanguage";

export const APP_LANGUAGE = {
  // km: {
  //   id:7,
  //   icon: <LanguageCambodian />,
  //   title: "Cambodian",
  //   value: "km",
  //   label: "KH",
  // name:"Cambodia"
  // },
  cn: {
    id: 3,
    icon: <LanguageChinese />,
    title: "中国",
    value: "cn",
    label: "CN",
    name:"China"
  },
  en: {
    id: 2,
    icon: <LanguageEnglish />,
    title: "English",
    value: "en",
    label: "EN",
    name:"English"
  },
  ko: {
    id:4,
    icon: <LanguageKorean />,
    title: "한국어",
    value: "ko",
    label: "KO",
    name:"Karean"
  },
  ms: {
    id:6,
    icon: <LanguageMalaysian />,
    title: "Bahasa Malaysia",
    value: "ms",
    label: "MY",
    name:"Malaysian"
  },
  // pl: {
  //   icon: <LanguageMalaysian />,
  //   title: "Malaysian",
  //   value: "pl",
  //   label: "PL",
  //   id: 8,
  //   name:"Malaysian"
  // },
  th: {
    id:5,
    icon: <LanguageThailand />,
    title: "ภาษาไทย",
    value: "th",
    label: "TH",
    name:"Thailand"
  },
  in: {
    id:9,
    icon: <LanguageIndonesia />,
    title: "Bahasa Indonesia",
    value: "in",
    label: "IN",
    name:"Indonesia"
  },
  vi: {
    id: 1,
    icon: <LanguageVietnamese />,
    title: "Tiếng Việt",
    value: "vi",
    label: "VN",
    name:"Vietnam"
  },
};

export const APP_NATION = {
  // km: {
  //   id: 7,
  //   icon: <LanguageCambodian />,
  //   title: "Cambodian",
  //   value: "km",
  //   name:"Cambodia",
  //   label:"Cambodia",
  // },
  cn: {
    id: 3,
    icon: <LanguageChinese />,
    title: "中国人",
    value: "cn",
    name:"China",
    label:"Trung Quốc",
    unit : "RMB"
  },
  en: {
    id: 2,
    icon: <LanguageEnglish />,
    title: "English",
    value: "en",
    name:"England",
    label:"Anh",
    unit : "GBP"
  },
  in: {
    id: 9,
    icon: <LanguageIndonesia />,
    title: "Indonesia",
    value: "in",
    name:"Indonesia",
    label:"Indonesia",
    unit : "IDR"
  },
  ko: {
    id: 4,
    icon: <LanguageKorean />,
    title: "Korean",
    value: "ko",
    name:"South Korea",
    label:"Hàn Quốc",
    unit : "KRW"
  },
  ml: {
    id: 6,
    icon: <LanguageMalaysian />,
    title: "Malaysian",
    value: "ml",
    name:"Malaysia",
    label:"Malaysia",
    unit: "MYR"
  },
  // pl: {
  //   icon: <LanguageMalaysian />,
  //   title: "Philippines",
  //   id: 8,
  //   value: "pl",
  //   name:"Philippines",
  //   label:"Philippines",
  // },
  th: {
    id: 5,
    icon: <LanguageThailand />,
    title: "Thai",
    value: "th",
    name:"Thailand",
    label:"Thái Lan",
    unit: "THB"
  },
  vi: {
    id: 1,
    icon: <LanguageVietnamese />,
    title: "Tiếng Việt",
    value: "vi",
    name:"Vietnam",
    label:"Việt Nam",
    unit: "VND"
  },

};
export const APP_PHONE = {
  // km: {
  //   id: 7,
  //   icon: <LanguageCambodian />,
  //   title: "Cambodian",
  //   value: "km",
  // },
  vi: {
    id: 1,
    icon: <LanguageVietnamese width={20} height={20} />,
    title: "Tiếng Việt",
    value: "+84",
  },
  cn: {
    id: 3,
    icon: <LanguageChinese width={20} height={20} />,
    title: "中国人",
    value: "+86",
  },
  en: {
    id: 2,
    icon: <LanguageEnglish width={20} height={20} />,
    title: "English",
    value: "+44",
  },
  in: {
    id: 9,
    icon: <LanguageIndonesia width={20} height={20} />,
    title: "Indonesia",
    value: "+62",
  },
  ko: {
    id: 4,
    icon: <LanguageKorean width={20} height={20} />,
    title: "Korean",
    value: "+82",
  },
  ml: {
    id: 6,
    icon: <LanguageMalaysian width={20} height={20} />,
    title: "Malaysian",
    value: "+60",
  },
  // pl: {
  //   icon: <LanguageMalaysian width={20} height={20} />,
  //   title: "Philipines",
  //   id: 8,
  //   value: "+63",
  // },
  th: {
    id: 5,
    icon: <LanguageThailand width={20} height={20} />,
    title: "Thai",
    value: "+66",
  },
};
export const getLanguageIdByValue = (value) => {
  let _appUserLanguageId = Object.values(APP_LANGUAGE).find(
    (e) => e.value == value,
  )?.id;
  return _appUserLanguageId;
};
