import Swal from "sweetalert2";
import "./BasicAlertPopup.scss";
export default function BasicAlertPopup(props) {
  if (!props) {
    return;
  }

  // sử dụng các thuộc tính props này
  const {
    color = "success", //màu sắc background
    icon = "", //loại icon
    title = "",
    content = "",
    footer = "",
    cancelButtonText = "",
    confirmButtonText = "",
    timer, //number(ms),
    allowOutsideClick = false, //click ra ngoài tắt popup
    onConfirm = () => { }, // Callback api
    showCloseButton,
    ...rest
  } = props;

  // chọn màu
  const colors = {
    primary: "background_primary_gradiant",
    secondary: "background_secondary_gradiant",
    danger: "background_danger_gradiant_popup",
    success: "background_success_gradiant",
    info: "background_info_gradiant",
  };
  const boxShadows = {
    primary: "box_shadow_primary",
    secondary: "box_shadow_secondary",
    danger: "box_shadow_danger",
    success: "box_shadow_success",
    info: "box_shadow_info",
  };

  //chọn icon
  const icons = {
    success: `<img src="/assets/images/basicAlert/IconDone.png" alt="" class="fullHeightWidth"/>`,
    success_transfer: `<img src="/assets/images/basicAlert/IconDoneTransfer.png" alt="" class="fullHeightWidth"/>`,
    danger: `<img src="/assets/images/basicAlert/IconDanger.png" alt="" class="fullHeightWidth"/>`,
    service: `<img src="/assets/images/basicAlert/IconService.png" alt="" class="fullHeightWidth"/>`,
    auth: `<img src="/assets/images/basicAlert/IconAuth.png" alt="" class="fullHeightWidth"/>`,
  };

  const basicAlertPopup = Swal.mixin({
    customClass: {
      icon: "BasicAlertPopup_icon",
      popup: `max_width_screen_project BasicAlertPopup_popup ${colors?.[color]} ${boxShadows?.[color]}  ${!icon ? "BasicAlertPopup_popup_no_icon" : ""}`,
      title: "BasicAlertPopup_title",
      htmlContainer: "BasicAlertPopup_container max_width_screen_project",
      actions: "BasicAlertPopup_actions",
      confirmButton: cancelButtonText
        ? "BasicAlertPopup_confirmButton_withCancel"
        : "BasicAlertPopup_confirmButton",
      cancelButton: "BasicAlertPopup_cancelButton",
      closeButton: "BasicAlertPopup_closeButton",
      container: "max_width_screen_project BasicAlertPopup_container",
      footer: "BasicAlertPopup_footer",
    },
  });

  return basicAlertPopup
    .fire({
      iconHtml: icons?.[icon],
      title: title,
      html: `<div class="BasicAlertPopup_content">${content}</div>`,
      reverseButtons: true,
      showCancelButton: cancelButtonText,
      showConfirmButton: confirmButtonText,
      cancelButtonText: cancelButtonText,
      confirmButtonText: confirmButtonText,
      showCloseButton:
        typeof showCloseButton === "boolean"
          ? showCloseButton
          : !confirmButtonText && !cancelButtonText && !timer,
      timer: timer,
      closeButtonHtml: `<img src="/assets/images/basicAlert/IconCloseCircle.png" alt="" height="100%" width="100%"/>`,
      backdrop: "var(--background_color_blur_opacity)",
      allowOutsideClick: allowOutsideClick,
      footer: footer,
      ...rest
    })
    .then((result) => {
      if (result.isConfirmed) {
        onConfirm();
      }
    });
}
