import React from "react"
import { IconIconCrypton } from "assets/icons/iconAdvanced"
export const MIN_STAKING = 10000

export const WALLET_RECORD_TYPE={
  ADMIN_ADJUST_INCREASE : 'ADMIN_ADJUST_INCREASE',
  ADMIN_ADJUST_DECREASE: 'ADMIN_ADJUST_DECREASE',
  SAVING: 'SAVING',
}
export const STACKING_ACTIVITY_STATUS = {
  NEW: 0,
  STAKING: 10,
  CANCELED: 20,
  COMPLETED: 30
}

export const STAKING_ACTIVITY_STATUS_STRING = {
  [STACKING_ACTIVITY_STATUS.NEW]: 'staking_new',
  [STACKING_ACTIVITY_STATUS.STAKING]: 'staking_staking',
  [STACKING_ACTIVITY_STATUS.CANCELED]: 'staking_cancel',
  [STACKING_ACTIVITY_STATUS.COMPLETED]: 'staking_completed'
}
export const BONUS_WALLET_LIST = [
  {
    id: 1,
    value: 'PIT',
    label: (
      <div style={{ display: 'flex', gap: '6px', alignItems: 'center' }}>
        <img style={{ width: '32px', height: '32px' }} src="/logo.png" alt="" />
        <div>PIT</div>
      </div>
    )
  },
  {
    id: 2,
    value: 'USDT',
    label: (
      <div style={{ display: 'flex', gap: '6px', alignItems: 'center' }}>
        <div>
          <IconIconCrypton width={32} height={32} />
        </div>
        <div>USDT</div>
      </div>
    )
  }
]
export const formatDisplayNumber = (value) => {
  if (value) {
    const number = value.toLocaleString()
    return number
  }
  return value
}

export const MESSAGE_TYPE = {
  SYSTEM_SUCCESS_MESSAGE: 1, // gửi tin nhắn thành công
  SYSTEM_FAILED_MESSAGE: 2, // gửi tin nhắn thất bại
  SYSTEM_NORMAL_MESSAGE: 3 // thông báo chung của user
}

export const VALUE_REGISTER = {
  MIN: 6,
  MAX: 20
}

export const MESSAGE_SUB_TYPE = {
  DEPOSIT_MESSAGE: 1,  // nạp usdt / banking
  WITHDRAW_MESSAGE: 2,  // rút usdt / banking
  USER_IDENTITY_MESSAGE: 3,  // thông báo của user
  SYSTEM_MESSAGE: 4,  // thông báo hệ thống
  TRANS_WALLET: 5,
  TRANS_COIN: 6,
  TRANS_MACHINE: 7,  // chuyển nhượng, nhận
  DETAIL_MACHINE: 8, // chi tiết máy thuê, tặng, thu hồi , hết hạn máy
  LEADER_BROAD: 9, //bảng xếp hạng
  LEVEL: 10, //cấp độ
  APY: 11, //lãi qua đêm
  STACKING: 12,  // thông báo staking
  DEPOSIT_MESSAGE_PIT: 13, // nạp pit
  WITHDRAW_MESSAGE_PIT: 14, // rút pit
}

export const SYSTEM_POSTS_CATEGORIES = {
  GUIDELINES: 1,
  EVENT_POSTS: 2,
  BRANCH_POLICY: 3,
  TERMS_OF_SERVICE: 4,
  PRIVACY_POLICY: 5,
  INTRODUCTION: 6
}

export const BANNER_CATEGORIES = {
  HOMEPAGE: 1
}
export const VERIFY_STEP = {
  Introduce: {
    index: 0,
    name: 'Introduce'
  },
  Info: {
    index: 1,
    name: 'Info'
  },
  Additional: {
    index: 2,
    name: 'Additional'
  },
  Identification: {
    index: 3,
    name: 'Identification'
  },
  IdentificationNote: {
    index: 4,
    name: 'IdentificationNote'
  },
  LoadIdentification: {
    index: 5,
    name: 'LoadIdentification'
  },
  People: {
    index: 6,
    name: 'People'
  }
}

export const WALLET_TYPE = {
  USDT: 'USDTWallet',
  BONUS_SALE: 'BonusSaleWallet', // ví hoa hồng thuê máy
  BONUS_BET: 'BonusBetWallet', // ví hoa hồng khai thác
  SAVING: 'SAVINGWallet', // Ví qua đêm
  POINT: 'PointWallet', // ví chính
  CRYPTO: 'CryptoWallet', // ví PIT
  STAKING: 'StakingWallet', // Ví staking
  ENVIRONMENT: 'EnvironmentWallet' // Ví môi trường
}

export const PAYMENT_UNIT = {
  // đơn vị tiền tệ
  GBP: 'GBP', //Bảng Anh
  KRW: 'KRW', //Hàn Quốc
  THB: 'THB', //Bath Thái
  MYR: 'MYR', //Ringgit Malaysia
  RMB: 'RMB', //Nhân dân tệ
  VND: 'VND', //Việt Nam
  IDR: 'IDR', //Đồng rupiah Indonesia
  PHP: 'PHP', //Philippine peso
  USDT: 'USDT',
  PIT: 'PIT'
}

export const USER_VERIFY_INFO_STATUS = {
  // trạng thái xác thực
  NOT_VERIFIED: 0,
  IS_VERIFIED: 1,
  VERIFYING: 2,
  REJECTED: 3
}

export const MAX_PACKAGE_CURRENT_STAGE = 6
export const MAX_LOGIN_FAILURE = 5
