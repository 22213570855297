import { useEffect, useState } from "react";
import PaymentMethod from "services/paymentMethod";
import useCommonHook from "./useCommonHook";
import BasicAlertPopup from "components/Basic/BasicAlertPopup/BasicAlertPopup";
import { useSystem } from "context/SystemContext";

export const MAX_PAYMENT_METHOD_OF_USER = 1;

export const PAYMENT_METHOD_TYPE = {
  ATM_BANK: 0,
  USDT: 1,
  PIT: 2
}

export const PAYMENT_METHOD_USAGE = {
  ATM_BANK: "ATM_BANK",
  PIT: "PIT",
  USDT: "USDT",
};

export const PAYMENT_METHOD_NETWORK = [
  {
    value: "Tron (TRC20)",
    label: "Tron (TRC20)",
  },
];

export const PAYMENT_UNIT = {
  England: 'GBP', //Bảng Anh
  SouthKorea: "KRW", //Hàn Quốc
  Thailand: "THB", //Bath Thái
  Malaysia: "MYR", //Ringgit Malaysia
  China: "RMB", //Nhân dân tệ
  Vietnam: "VND", //Việt Nam
  Indonesia: "IDR", //Đồng rupiah Indonesia 
  Philipin: "PHP", //Philippine peso
  USDT: "USDT",
  PIT: "PIT",
}

export const DEPOSIT_TRX_CATEGORY = {
  BANK: 'ATM/BANK',
  BLOCKCHAIN: 'BLOCKCHAIN',
  USDT: 'USDT',
  PIT: 'PIT',
}

const usePaymentMethods = (paymentMethodType = "USDT") => {
  const { translate } = useCommonHook();
  const { system } = useSystem()
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    PaymentMethod.getList({
      filter: {
        paymentMethodType: PAYMENT_METHOD_TYPE?.[paymentMethodType],
      }
    }).then((response) => {
      setPaymentMethods(response?.data?.data || []);
      setLoading(false);
    });
  }, [paymentMethodType]);

  const showPopupService = () => {
    const itemsTabShow = {
      USDT: {
        messageService: translate(
          "usePaymentMethod.You_can_only_add_{x}_usdt_wallet_address._Contact_support_to_be_answered",
          {
            // x: +system?.maxLimitedPaymentUSDT >= 10 ? system?.maxLimitedPaymentUSDT : `0${system?.maxLimitedPaymentUSDT || 1}`
            x: `0${MAX_PAYMENT_METHOD_OF_USER}`
          }
        )
      },
      PIT: {
        messageService: translate(
          "usePaymentMethod.You_can_only_add_{x}_pit_wallet_address._Contact_support_to_be_answered",
          {
            // x: +system?.maxLimitedPaymentPIT >= 10 ? system?.maxLimitedPaymentPIT : `0${system?.maxLimitedPaymentPIT || 1}`
            x: `0${MAX_PAYMENT_METHOD_OF_USER}`
          }
        )
      },
      ATM_BANK: {
        messageService: translate(
          "usePaymentMethod.You_can_only_add_{x}_account_bank._Contact_support_to_be_answered",
          {
            // x: +system?.maxLimitedPaymentBank >= 10 ? system?.maxLimitedPaymentBank : `0${system?.maxLimitedPaymentBank || 1}`
            x: `0${MAX_PAYMENT_METHOD_OF_USER}`
          }
        )
      }
    };
    BasicAlertPopup({
      content: itemsTabShow?.[paymentMethodType]?.messageService,
      color: "info",
      icon: "service",
      confirmButtonText: translate("contact"),
      showCloseButton: true,
      onConfirm: () => {
        window.open(
          // system?.[
          // `supportChatUrl${Object.values(APP_LANGUAGE)
          //   .find((item) => item.id === user?.appUserLanguageId)
          //   ?.value?.toUpperCase()}`
          // ]
          system?.supportChatUrlEN,
          "_blank"
        );
      },
    });
  };
  return { data: paymentMethods, setData: setPaymentMethods, loading, showPopupService };
};

export { usePaymentMethods };