import Request from "./request";

export default class LoginService {
  static async Signin(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: "POST",
        path: "AppUsers/loginUser",
        data,
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result;

        if (statusCode === 200) {
          return resolve({ isSuccess: true, data });
        } else {
          return resolve({ isSuccess: false, message, error });
        }
      });
    });
  }
  static async sendEmailOTP(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: "POST",
        path: "OTPMessage/sendOTPToUserEmail",
        data,
      }).then((result = {}) => {
        const { statusCode, data, message } = result;

        if (statusCode === 200) {
          return resolve(result);
        } else {
          return resolve(result);
        }
      });
    });
  }
  static async checkUsernameDuplicate(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: "POST",
        path: "AppUsers/user/checkExistUsername",
        data,
      }).then((result = {}) => {
        const { statusCode, data, message } = result;

        if (statusCode === 200) {
          return resolve(result);
        } else {
          return resolve(result);
        }
      });
    });
  }
  static async verifyOtp(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: "POST",
        path: "AppUsers/confirmEmailOTP",
        data,
      }).then((result = {}) => {
        const { statusCode, data, message } = result;
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data });
        } else {
          return resolve({ isSuccess: false, message });
        }
      });
    });
  }
  static async confirmEmailOtp(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: "POST",
        path: "AppUsers/user/userConfirmUserOTP",
        data,
      }).then((result = {}) => {
        const { statusCode, data, message,error } = result;
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data });
        } else {
          return resolve({ isSuccess: false, message,error });
        }
      });
    });
  }
  static async verify2Fa(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: "POST",
        path: "AppUsers/verify2FA",
        data,
      }).then((result = {}) => {
        const { statusCode, data, message } = result;
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data });
        } else {
          return resolve({ isSuccess: false, message });
        }
      });
    });
  }
  static async Register(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: "POST",
        path: "AppUsers/user/registerUserByUserNameWithOTP",
        data,
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result;

        if (statusCode === 200) {
          return resolve({ isSuccess: true, result });
        } else {
          return resolve({ isSuccess: false, result });
        }
      });
    });
  }


  static async ForgotPass(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: "POST",
        path: "AppUsers/user/forgotPasswordEmailOTP",
        data,
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result;

        if (statusCode === 200) {
          return resolve({ isSuccess: true, statusCode, data, message, error });
        } else {
          return resolve({ isSuccess: false, statusCode, data, message, error });
        }
      });
    });
  }
  static async sendEmailOTPForgotPass(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: "POST",
        path: "AppUsers/sendEmailOTP",
        data,
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result;

        if (statusCode === 200) {
          return resolve({ isSuccess: true, statusCode, data, message, error });
        } else {
          return resolve({ isSuccess: false, statusCode, data, message, error });
        }
      });
    });
  }

  static async ChangeUserPassWord(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: "POST",
        path: "users/change-password",
        data,
      }).then((result = {}) => {
        const { statusCode, data, message } = result;

        if (statusCode === 200) {
          return resolve({ isSuccess: true, data });
        } else {
          return resolve({ isSuccess: false, message });
        }
      });
    });
  }
  static async setPassword(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: "POST",
        path: "users/set-password",
        data,
      }).then((result = {}) => {
        const { statusCode, data, message } = result;

        if (statusCode === 200) {
          return resolve({ isSuccess: true, data });
        } else {
          return resolve({ isSuccess: false, message });
        }
      });
    });
  }

  static async verifyAccount(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: "POST",
        path: "AppUsers/user/checkExistingAccount",
        data,
      }).then((result = {}) => {
        const { statusCode } = result;

        if (statusCode === 200) {
          return resolve({ isSuccess: true });
        } else {
          return resolve({ isSuccess: false });
        }
      });
    });
  }

  static async verifyAccount(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: "POST",
        path: "AppUsers/user/checkExistingAccount",
        data,
      }).then((result = {}) => {
        const { statusCode } = result;

        if (statusCode === 200) {
          return resolve({ isSuccess: true });
        } else {
          return resolve({ isSuccess: false });
        }
      });
    });
  }

  static async updatePass(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: "POST",
        path: "AppUsers/user/changePasswordviaEmailOTP",
        data,
      }).then((result = {}) => {
        const { statusCode, error, message } = result;

        if (statusCode === 200) {
          return resolve({ isSuccess: true });
        } else {
          return resolve({ isSuccess: false, error });
        }
      });
    });
  }
}
