import { WALLET } from "hooks/management.hook";
import React, {
  createContext,
  useContext,
  useMemo,
} from "react";
import {
  canWithdraw,
  useUser,
  isVerified,
  hasSecondaryPassword,
} from "./UserContext";
import { find, omit } from "lodash";
import PaymentWithdrawTransaction from "services/paymentWithdrawTransaction";
import PaymentExchangeTransaction from "services/paymentExchangeTransaction";
import { removeCommaFromPayload } from "helper/common";
import BasicAlertPopup from "components/Basic/BasicAlertPopup/BasicAlertPopup";
import useCommonHook from "hooks/useCommonHook";
const WalletContext = createContext(null);

export function WalletProvider({ children }) {
  const { user, refresh } = useUser();
  const { translate } = useCommonHook()

  const stakingBalance = useMemo(() => {
    return find(user?.wallets, { walletType: WALLET.STAKING })?.balance || 0;
  }, [user]);
  const savingBalance = useMemo(() => {
    return find(user?.wallets, { walletType: WALLET.SAVING })?.balance || 0;
  }, [user]);
  const cryptoBalance = useMemo(() => {
    return find(user?.wallets, { walletType: WALLET.CRYPTO })?.balance || 0;
  }, [user]);
  const pointBalance = useMemo(() => {
    return find(user?.wallets, { walletType: WALLET.POINT })?.balance || 0;
  }, [user]);
  const bonusSaleBalance = useMemo(() => {
    return find(user?.wallets, { walletType: WALLET.BONUS_SALE })?.balance || 0;
  }, [user]);
  const bonusBetBalance = useMemo(() => {
    return find(user?.wallets, { walletType: WALLET.BONUS_BET })?.balance || 0;
  }, [user]);
  const bonusSaleWallet = useMemo(() => {
    return find(user?.wallets, { walletType: WALLET.BONUS_SALE }) || {};
  }, [user]);
  const bonusBetWallet = useMemo(() => {
    return find(user?.wallets, { walletType: WALLET.BONUS_BET }) || {};
  }, [user]);

  function withdrawUsdt(values, callback) {
    if (canWithdraw(user)) {
      PaymentWithdrawTransaction.requestWithdrawUSDT(values).then((result) => {
        const { isSuccess, error } = result;
        if (isSuccess) {
          BasicAlertPopup({
            title: translate("withdraw_success"),
            icon: "success",
            color: "success",
          });
          refresh();
        } else {
          console.log(
            "%cWalletContext.js line:62 error",
            "color: #007acc;",
            error,
          );
          BasicAlertPopup({
            title: errorMessage(error),
            icon: "danger",
            color: "danger",
          });
        }
        if (callback && callback instanceof Function) {
          callback();
        }
      });
    } else {
      if (isVerified(user) === false) {
        BasicAlertPopup({
          title: translate("kyc_required"),
          icon: "danger",
          color: "danger",
        });
      } else if (
        hasSecondaryPassword(user) &&
        hasSecondaryPassword(user) !== null
      ) {
        BasicAlertPopup({
          title: translate("secondpass_required"),
          icon: "danger",
          color: "danger",
        });
      }
      if (callback && callback instanceof Function) {
        callback();
      }
    }
  }

  function errorMessage(error) {
    return error ? translate(error.toLowerCase()) : translate("something_wrong");
  }

  function withdrawBtc(values, callback) {
    if (canWithdraw(user)) {
      PaymentWithdrawTransaction.requestWithdrawBTC(
        omit(values, "paymentToAmount"),
      ).then((result) => {
        const { isSuccess, error } = result;
        if (isSuccess) {
          BasicAlertPopup({
            title: translate("withdraw_success"),
            icon: "success",
            color: "success",
          });
          refresh();
        } else {
          console.log(
            "%cWalletContext.js line:92 error",
            "color: #007acc;",
            error,
          );
          BasicAlertPopup({
            title: error ? translate(error.toLowerCase()) : translate("something_wrong"),
            icon: "danger",
            color: "danger",
          });
        }
        if (callback && callback instanceof Function) {
          callback();
        }
      });
    } else {
      BasicAlertPopup({
        title: translate("kyc_required"),
        icon: "danger",
        color: "danger",
      });
      if (callback && callback instanceof Function) {
        callback();
      }
    }
  }

  function exchangeFac(values, callback) {
    PaymentExchangeTransaction.exchangeFAC(
      omit(removeCommaFromPayload(values), "paymentToAmount"),
    ).then((result) => {
      const { isSuccess, message } = result;
      if (isSuccess) {
        BasicAlertPopup({
          title: translate("exchange_fac_success"),
          icon: "success",
          color: "success",
        });

        refresh();
      } else {
        BasicAlertPopup({
          title: message || translate("something_wrong"),
          icon: "danger",
          color: "danger",
        });
      }
      if (callback && callback instanceof Function) {
        callback();
      }
    });
  }

  function exchangePoint(values, callback) {
    PaymentExchangeTransaction.exchangePOINT(
      omit(values, "paymentToAmount"),
    ).then((result) => {
      const { isSuccess, message } = result;
      if (isSuccess) {
        BasicAlertPopup({
          title: translate("exchange_to_fac_success"),
          icon: "success",
          color: "success",
        });

        refresh();
      } else {
        BasicAlertPopup({
          title: message || translate("something_wrong"),
          icon: "danger",
          color: "danger",
        });
      }
      if (callback && callback instanceof Function) {
        callback();
      }
    });
  }

  return (
    <WalletContext.Provider
      value={{
        wallet: {
          stakingBalance,
          savingBalance,
          cryptoBalance,
          pointBalance,
          bonusSaleWallet,
          bonusBetWallet,
          bonusBetBalance,
          bonusSaleBalance,
          // withdrawUsdt,
          // withdrawBtc,
          // exchangeFac,
          // exchangePoint,
        },
      }}
    >
      {children}
    </WalletContext.Provider>
  );
}

export function useWallet() {
  return useContext(WalletContext);
}

export const DEPOSIT_TRX_UNIT = {
  VND: 'VND',
  USDT: 'USDT',
  PIT: 'PIT'
}

export const WITHDRAW_TRX_UNIT = {
  VND: 'VND',
  USDT: 'USDT',
  PIT: 'PIT'
}