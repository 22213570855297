import React, { useEffect, useState } from "react";
import { useUser } from "context/UserContext";
import "./PopupValidatorPassword.scss";
import { IconCloseCircle, IconSuccessCheck } from "assets/icons/iconBasic";

export default function PopupValidatorPassword({
  className,
  value = "",
  translate,
}) {
  const [upperValidated, setUpperValidated] = useState(false);
  const [numberValidated, setNumberValidated] = useState(false);
  const [minLengthValidated, setMinLengthValidated] = useState(false);
  const [maxLengthValidated, setMaxLengthValidated] = useState(false);
  const { user } = useUser();

  const handleChangePassword = (value) => {
    const upper = new RegExp("(?=.*[A-Z])");
    const number = new RegExp("(?=.*[0-9])");
    const minlength = new RegExp("(?=.{8,})");
    const maxlength = new RegExp("(?=.{21,})");
    if (upper.test(value)) {
      setUpperValidated(true);
    } else {
      setUpperValidated(false);
    }
    if (number.test(value)) {
      setNumberValidated(true);
    } else {
      setNumberValidated(false);
    }
    if (minlength.test(value)) {
      setMinLengthValidated(true);
    } else {
      setMinLengthValidated(false);
    }
    if (maxlength.test(value)) {
      setMaxLengthValidated(false);
    } else {
      setMaxLengthValidated(true);
    }
  };
  useEffect(() => {
    handleChangePassword(value);
  }, [value]);

  return (
    <main className={className}>
      <div className="validate-item">
        {upperValidated ? (
          <span className="list-icon green">
            <IconSuccessCheck />
          </span>
        ) : (
          <span className="list-icon error-icon">
            <IconCloseCircle width={14} height={14} />
          </span>
        )}
        <div className="validate-text">{translate("passUppercase")}</div>
      </div>
      <div className="validate-item">
        {numberValidated ? (
          <span className="list-icon green">
            <IconSuccessCheck />
          </span>
        ) : (
          <span className="list-icon error-icon">
            <IconCloseCircle width={14} height={14} />
          </span>
        )}
        <div className="validate-text">{translate("passNumber")}</div>
      </div>
      <div className="validate-item">
        {minLengthValidated ? (
          <span className="list-icon green">
            <IconSuccessCheck />
          </span>
        ) : (
          <span className="list-icon error-icon">
            <IconCloseCircle width={14} height={14} />
          </span>
        )}
        <div className="validate-text">{translate("passMinLength")}</div>
      </div>
      <div className="validate-item">
        {maxLengthValidated ? (
          <span className="list-icon green">
            <IconSuccessCheck />
          </span>
        ) : (
          <span className="list-icon error-icon">
            <IconCloseCircle width={14} height={14} />
          </span>
        )}
        <div className="validate-text">{translate("passMaxLength")}</div>
      </div>
    </main>
  );
}
