/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from "react";
import "./Footer.scss";
import { routes } from "App";
import { useIntl } from "react-intl";
import { NavHome } from "assets/icons/iconNavbar";
import { NavHomeBold } from "assets/icons/iconNavbar";
import { NavBranch } from "assets/icons/iconNavbar";
import { NavBranchBold } from "assets/icons/iconNavbar";
import { NavFactory } from "assets/icons/iconNavbar";
import { NavFactoryBold } from "assets/icons/iconNavbar";
import { NavUser } from "assets/icons/iconNavbar";
import { NavUserBold } from "assets/icons/iconNavbar";
import classNames from "classnames";
import { NavCartLarge } from "assets/icons/iconNavbar";
import { useHistory } from "react-router-dom";
export default function Footer() {
  const history = useHistory();
  const intl = useIntl();
  const t = useCallback((id) => intl.formatMessage({ id }), [intl]);

  const listNav = [
    {
      title: t("homepage"),
      path: routes.home.path,
      icon: <NavHome />,
      iconActive: <NavHomeBold />,
    },
    {
      title: t("branch"),
      path: routes.branch.path,
      icon: <NavBranch />,
      iconActive: <NavBranchBold />,
    },
    {
      title: t("store"),
      path: routes.shop.path,
      icon: <NavCartLarge />,
      iconActive: <NavCartLarge />,
      isSpecial: true,
    },
    {
      title: t("factory"),
      path: routes.factory.path,
      icon: <NavFactory />,
      iconActive: <NavFactoryBold />,
    },
    {
      title: t("its_mine"),
      path: routes.managementProfile.path,
      icon: <NavUser />,
      iconActive: <NavUserBold />,
    },
  ];

  return (
    <footer id="Footer" className="max_width_screen_project">
      <div className="footer_container">
        {listNav.map((item) => {
          const active = window.location.pathname === item.path;
          return (
            <div
              key={item.title}
              className={classNames({
                footer_item: !item.isSpecial,
                footer_item_special: item.isSpecial,
                footer_item_active: active,
              })}
              onClick={() => {
                history.push(item.path);
              }}
            >
              <div
                style={{ position: "absolute" }}
                className={classNames({
                  footer_item_activeLine: active && !item.isSpecial,
                })}
              ></div>
              <div
                className={classNames({
                  footer_item_icon: !item.isSpecial,
                  footer_item_icon_special: item.isSpecial,
                })}
              >
                {active ? item.iconActive : item.icon}
              </div>
              <p>{item.title}</p>
            </div>
          );
        })}
      </div>
    </footer>
  );
}
