import { CopyIcon, PlainIcon } from "assets/icons";
import { useUser } from "context/UserContext";
import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import style from "./index.module.scss";
import { Card } from "antd";
import Introduces from "assets/images/Introduces.png";
import { Header } from "components/Layout/Header/Header";
import { useModal } from "context/ModalContext";
import BasicButton from "components/Basic/BasicButton/BasicButton";
import useCommonHook from "hooks/useCommonHook";
import { routes } from "App";
import { APP_NATION } from 'constants/languageConstant';
import { APP_IMAGES } from "assets/images/AppImages";

export const Introduce = ({ history }) => {
  const { copyToClipboard } = useCommonHook();
  // useIntl template
  const modal = useModal();
  const intl = useIntl();
  const t = useCallback(
    (id) => {
      return intl.formatMessage({ id });
    },
    [intl],
  );
  const { user } = useUser();

  const goToPageStore = () => {
    history.push(routes.shop.path);
    modal.hide();
  };

  const ImageNation = useCallback(() => {
    if (user?.appUserLanguageId === APP_NATION.vi.id) { 
      return APP_IMAGES?.imagebannerVN
    }
    if (user?.appUserLanguageId === APP_NATION.cn.id) { 
      return APP_IMAGES?.imagebannerCN
    }
    if (user?.appUserLanguageId === APP_NATION.en.id) { 
      return APP_IMAGES?.imagebannerEN
    }
    if (user?.appUserLanguageId === APP_NATION.in.id) { 
      return APP_IMAGES?.imagebannerIN
    }
    if (user?.appUserLanguageId === APP_NATION.ko.id) { 
      return APP_IMAGES?.imagebannerKR
    }
    if (user?.appUserLanguageId === APP_NATION.ml.id) { 
      return APP_IMAGES?.imagebannerML
    }
    if (user?.appUserLanguageId === APP_NATION.th.id) { 
      return APP_IMAGES?.imagebannerTL
    }
  }, [user])

  return (
    <>
      <Header backgroundColor="secondary" title={t("introduction")} />
      <div className={style["introduce"]}>
        <div className={style["introduce_card"]}>
          <div className={style["introduce_card_images"]}>
            <img
              src={ImageNation()}
              className="img-fluid"
              style={{ width: "calc(100vw - 10px)" }}
            />
          </div>
          {user?.enableReferCode === 0 ? (
            <div className={style["introduce_card_folder"]}>
              <img src={Introduces} className="img-fluid" />
              <div className={style["introduce_card_folder_text"]}>
                {t("introduce_text")}
              </div>
              <BasicButton
                width={window.innerWidth <= 280 ? "170px" : "270px"}
                onClick={() => goToPageStore()}
              >
                {t("machine_now")}
              </BasicButton>
            </div>
          ) : (
            <div>
              <div className={style["introduce_card_images"]}>
                <Card
                  bordered={false}
                  style={{
                    width: "100%",
                    borderRadius: 12,
                  }}
                >
                  <div className={style["introduce_card_qr"]}>
                    {user && user.referQRCode && (
                      <img src={user?.referQRCode} alt="" />
                    )}
                  </div>
                  <div>
                    <div>
                      <div className={style["introduce_card_title"]}>
                        {t("unique_link")}
                      </div>
                      <div
                        style={{
                          backgroundColor: "var(--background_color_active)",
                          paddingBottom: "10px",
                          paddingTop: "10px",
                          borderRadius: "5px",
                        }}
                        className="mt-2 d-flex justify-content-between align-items-center"
                      >
                        <div className={style["introduce_card_text"]}>
                          {user?.referLink}
                        </div>
                        <div
                          onClick={() => {
                            copyToClipboard(user?.referLink || "");
                          }}
                        >
                          <CopyIcon />
                        </div>
                      </div>
                    </div>
                    <div className="mt-3">
                      <div className={style["introduce_card_title"]}>
                        {t("invite_code")}
                      </div>
                      <div
                        style={{
                          backgroundColor: "var(--background_color_active)",
                          paddingBottom: "10px",
                          paddingTop: "10px",
                          borderRadius: "5px",
                        }}
                        className="mt-2 d-flex justify-content-between align-items-center"
                      >
                        <div className={style["introduce_card_text"]}>
                          {user?.referCode}
                        </div>
                        <div
                          onClick={() => {
                            copyToClipboard(user?.referCode || "");
                          }}
                        >
                          <CopyIcon />
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
              <div className={style["introduce_card_bugi"]}>
                <div
                  style={{
                    width: "100%",
                    borderRadius: 4,
                    border: "1px solid #D3ADF7",
                    marginBottom: "50px",
                    background: "#F9F0FF",
                  }}
                  className={style["introduce_card_bugi_tera"]}
                >
                  <div className={style["introduce_card_bugi_tera_note"]}>
                    <div
                      className={style["introduce_card_bugi_tera_note_icon"]}
                    >
                      <PlainIcon />
                    </div>
                    <div
                      className={style["introduce_card_bugi_tera_note_content"]}
                    >
                      <div
                        className={style["introduce_card_bugi_tera_content"]}
                      >
                        {t("note_one")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
