
import React from "react";
import "./index.scss";
import { useParams } from "react-router-dom";
import PaymentMethodInsertUsdt from "./components/PaymentMethodInsertUsdt";
import { PAYMENT_METHOD_USAGE } from "hooks/usePaymentMethod";
import PaymentMethodInsertPit from "./components/PaymentMethodInsertPit";
import PaymentMethodInsertBank from "./components/PaymentMethodInsertBank";

const PaymentMethodInsert = () => {
  const { type } = useParams();
  const types = {
    [PAYMENT_METHOD_USAGE.USDT]: {
      component: <PaymentMethodInsertUsdt />
    },
    [PAYMENT_METHOD_USAGE.PIT]: {
      component: <PaymentMethodInsertPit />
    },
    [PAYMENT_METHOD_USAGE.ATM_BANK]: {
      component: <PaymentMethodInsertBank />
    },
  };
  return (
    <div className="PaymentMethodInsert">
      {
        types?.[type]?.component
      }
    </div>
  );
};
export default PaymentMethodInsert;
