import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useModal } from "context/ModalContext";
import { useUser } from "context/UserContext";
import AppUsers from "services/apppUsers";
import OtpInput from "react-otp-input";
import "./index.scss";

import { Form, Button } from "antd";
import BasicAlertPopup from "components/Basic/BasicAlertPopup/BasicAlertPopup";
import { Header } from "components/Layout/Header/Header";

const ConfirmAuth = (props) => {
  const { user, refresh } = useUser();
  const [otp, setOTP] = useState("");
  const [checkOtp, setCheckOtp] = useState(true);
  const { history } = props;
  const modal = useModal();
  console.log("ConfirmAuth ~ modal:", modal)

  const onChangeOtpInput = (e) => {
    setOTP(e);
    props.setOtp && props.setOtp(e);
  };
  // useIntl template
  const intl = useIntl();

  const translate = useCallback(
    (id) => {
      return intl.formatMessage({ id });
    },
    [intl],
  );
  const handleGoToProfile= ()=>{
    modal.hide()
    history.push('/management/profile')
  }
  const onFinish=(otpCode) => {
    let values={
      id:user?.appUserId,
      otpCode:otpCode
    }
    AppUsers.verify2FA(values).then((result) => {
      const { isSuccess, message, data } = result;
      if (!isSuccess || !data) {
        BasicAlertPopup({
          title: translate('invalidOTP'),
          color: "danger",
          icon: "danger",
          showCloseButton:true,
        })
        return;
      } else {
        BasicAlertPopup({
          title: translate("turn_on_sec"),
          content:translate("turn_on_sec_note"),
          color: "success",
          icon: "auth",
          showCloseButton:false,
          confirmButtonText:translate("continue"),
          onConfirm:handleGoToProfile
        })
        refresh()
      }
    });
  }
  useEffect(() => {
    async function check() {
      if (otp.length === 6) {
        onFinish(otp);
      }
    }
    check()
  }, [otp])
  return (
    <>
      <Header
       title={translate("VerifyViaAuth")}
       iconsLeft={{
          icon: "back",
          onClick: () => {
            modal.back();
          }
        }}
       />
      <section className="authenticator__2fa">
        <div>
          <div>
            <div className="authenticator__title">{translate("input_code")}</div>
            <div className="authenticator__subtitle">{translate("input_code_content")}</div>
          </div>
          <Form
            name="confirmOtpForm"
            autoComplete="off"
            onFinish={(data) => {
              onFinish(data)
            }}
          >
            <Form.Item
              name="otpCode"
              className={`mb-1 ${checkOtp ? "otp__input-otp" : "otp__input-error-otp"}`}
            >
              <OtpInput
                numInputs={6}
                isInputNum={true}
                className="m-auto"
                disabled={props.disabled}
                shouldAutoFocus={true}
                inputStyle={`${otp ? 'inputOTP' : ''}`}
                onChange={(value) => {
                  return onChangeOtpInput(value)}
                }
                separator={<span className=""></span>}
              />
            </Form.Item>
            <div
              style={{
                marginTop: 12,
                visibility: checkOtp ? "hidden" : "visible",
              }}
              className="otp__error_otp"
              id="checkOtp"
            >
              {intl.formatMessage({ id: "invalidOTP" })}
            </div>
          </Form>
        </div>
      </section>
    </>
  );
};

export default ConfirmAuth;
