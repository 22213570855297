import React, { useEffect, useState } from "react";
import "./BasicSelect.scss";
import { Select } from "antd";
import BasicInput from "../BasicInput/BasicInput";
import { IconSearch } from "assets/icons/iconBasic";

export default function BasicSelect({
  className = "",
  placeHolderSearch,
  filterOption,
  onSearch,
  options = [],
  showSearch,
  ...props
}) {
  const [filteredOptions, setFilteredOptions] = useState(options || []);

  useEffect(() => {
    setFilteredOptions(options)
    return () => { setFilteredOptions([]) }
  }, [options])

  function handleSearch(value) {
    if (value && showSearch) {
      setFilteredOptions(
        options.filter((option) =>
          filterOption
            ? filterOption(value, option)
            : option.value.toLowerCase().includes(value.toLowerCase())
            || option.label.toLowerCase().includes(value.toLowerCase())
        )
      );
    } else {
      setFilteredOptions(options);
    }
  }

  function handleChangeInputSearch(e) {
    const value = e.target.value
    onSearch && onSearch(value)
    handleSearch(value)
  }

  return <Select
    className={`BasicSelect ${className}`}
    size="large"
    options={filteredOptions}
    dropdownRender={(menu) => (
      <div>
        {
          showSearch && <div id="search" className="BasicSelect_search">
            <BasicInput
              onChange={(e) => handleChangeInputSearch(e)}
              placeholder={placeHolderSearch}
              suffix={<IconSearch />}
            />
          </div>
        }
        <div className="BasicSelect_menu">
          {menu}
        </div>
      </div>
    )}
    {...props}
  />;
}
