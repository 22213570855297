import { Header } from "components/Layout/Header/Header";
import useCommonHook from "hooks/useCommonHook";
import React, { useEffect, useState } from "react";
import { PAYMENT_METHOD_USAGE } from "hooks/usePaymentMethod";
import BasicInput from "components/Basic/BasicInput/BasicInput";
import { PAYMENT_METHOD_NETWORK } from "hooks/usePaymentMethod";
import { useUser } from "context/UserContext";
import SendCode from "components/Advanced/SendCode/SendCode";
import classNames from "classnames";
import { IconWarning } from "assets/icons/iconBasic";
import BasicButton from "components/Basic/BasicButton/BasicButton";
import BasicAlertPopup from "components/Basic/BasicAlertPopup/BasicAlertPopup";
import PaymentMethod from "services/paymentMethod";
import { PAYMENT_METHOD_TYPE } from "hooks/usePaymentMethod";
import { errorOtpPaymentMethod, errorPaymentMethod } from "./PaymentMethodInsertUsdt";  
import usePaymentMethodInsert from "../actions/useMethodInsertAction";
import { useHistory, useParams } from "react-router-dom";
import { usePaymentMethods } from "hooks/usePaymentMethod";
import { PAYMENT_UNIT } from "hooks/usePaymentMethod";
import { MAX_PAYMENT_METHOD_OF_USER } from "hooks/usePaymentMethod";

const PaymentMethodInsertPit = () => {
  const history = useHistory()
  const { type } = useParams();
  const { translate } = useCommonHook();
  const { user } = useUser();
  const { handleFormatHideEmail, handleGetOnlyString } = usePaymentMethodInsert()
  const [dataToAction, setDataToAction] = useState({
    paymentMethodName: PAYMENT_METHOD_USAGE.PIT,
    paymentMethodReceiverName: PAYMENT_METHOD_NETWORK[0].value,
  });
  const [checkDisabled, setCheckDisabled] = useState({
    sendCode: true,
    submit: true,
  });
  const [errorOTP, setErrorOTP] = useState(undefined);
  const { data, showPopupService } = usePaymentMethods(type);

  useEffect(() => {
    if (dataToAction?.paymentMethodIdentityNumber && user.email && !errorOTP) {
      setCheckDisabled({
        submit: true,
        sendCode: false,
      });
      if (dataToAction?.otp) {
        setCheckDisabled({
          sendCode: false,
          submit: false,
        });
      }
    } else {
      setCheckDisabled({
        sendCode: true,
        submit: true,
      });
    }
  }, [dataToAction, errorOTP]);

  function _handleInserPaymentMethod() {
    if (data?.length >= MAX_PAYMENT_METHOD_OF_USER) {
      return showPopupService()
    }
    const params = {
      data: {
        paymentMethodName: dataToAction?.paymentMethodName,
        paymentMethodIdentityNumber: dataToAction?.paymentMethodIdentityNumber,
        paymentMethodReceiverName: dataToAction?.paymentMethodReceiverName,
        paymentMethodType: PAYMENT_METHOD_TYPE[type],
        paymentMethodUnit: PAYMENT_UNIT.PIT,
      },
      otp: dataToAction?.otp,
    };

    PaymentMethod.insertPaymentMethod(params).then((response) => {
      const { isSuccess, error } = response;
      if (isSuccess) {
        BasicAlertPopup({
          title: translate("Confirmed_successfully"),
          content: translate("Your_PIT_wallet_has_been_linked"),
          color: "success",
          icon: "success",
        });
        history.goBack()
      } else {
        if (error === errorOtpPaymentMethod?.[error]) {
          setErrorOTP(translate(error));
        }
        // errorPaymentMethod?.[error] sẽ kiểm tra error có trong errorPaymentMethod hay không để thông báo lỗi
        else if (errorPaymentMethod?.[error]) {
          BasicAlertPopup({
            title: translate(`Link_failed`),
            color: "danger",
            icon: "danger",
            content: translate(errorPaymentMethod?.[error]),
          });
        } else {
          BasicAlertPopup({
            title: translate(`Link_failed`),
            color: "danger",
            icon: "danger",
            content: translate(`Please_check_the_information_again`),
          });
        }
      }
    });
  }
  return (
    <div className="PaymentMethodInsert">
      <Header title={translate("Add_wallet_PIT")}></Header>
      <p className="PaymentMethodInsert_title">{translate("Information_wallet")}</p>
      <div className="PaymentMethodInsert_content">
        <div>
          <p className="PaymentMethodInsert_content_label">
            {translate("Crypto_money")}
          </p>
          <BasicInput
            value={dataToAction?.paymentMethodName}
            disabled={true}
          />
        </div>

        <div>
          <p className="PaymentMethodInsert_content_label">
            {translate("network")}
          </p>
          <BasicInput
            value={dataToAction?.paymentMethodReceiverName}
            disabled={true}
          />
        </div>

        <div>
          <p className="PaymentMethodInsert_content_label">
            {translate("Wallet_address")}
          </p>
          <BasicInput
            placeholder={translate("Enter_wallet_address")}
            value={dataToAction?.paymentMethodIdentityNumber}
            onChange={(e) => {
              setDataToAction({
                ...dataToAction,
                paymentMethodIdentityNumber: e.target.value,
              });
            }}
          />
        </div>
        <div>
          <p className="PaymentMethodInsert_content_label">Email</p>
          <BasicInput
            value={handleFormatHideEmail(user.email)}
            disabled={true}
            suffix={
              <SendCode disabled={checkDisabled.sendCode} email={user.email} />
            }
          />
        </div>
        <div>
          <p className="PaymentMethodInsert_content_label">
            {translate("verification")}
          </p>
          <BasicInput
            className={errorOTP ? "PaymentMethodInsert_errorBorder" : ""}
            value={dataToAction?.otp}
            placeholder={translate("inputOTP")}
            onChange={(e) => {
              if (e.target.value.length <= 6) {
                setDataToAction({
                  ...dataToAction,
                  otp: handleGetOnlyString(e.target.value, dataToAction?.otp),
                });
                setErrorOTP(undefined);
              }
            }}
          />
          <p className="PaymentMethodInsert_errorOTP">{errorOTP}</p>
        </div>

        <div className="background_primary_outlined PaymentMethodInsert_note ">
          <IconWarning />
          <span>{translate("Note_add_wallet_crypto")}</span>
        </div>

        <BasicButton
          className={classNames({ isDisable: checkDisabled.submit })}
          onClick={() => {
            _handleInserPaymentMethod();
          }}
        >
          {translate("Confirm")}
        </BasicButton>
      </div>
    </div>
  );
};
export default PaymentMethodInsertPit;
