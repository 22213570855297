import { useUser } from "context/UserContext";
import { useMemo } from "react";

const usePaymentMethodInsert = () => {
  const { user } = useUser()

  function handleFormatHideAccountName(value) {
    if (value?.length > 2) {
      return (
        value?.charAt(0) +
        "*".repeat(value?.length - 2) +
        value?.charAt(value?.length - 1)
      );
    }
    return value;
  }
  function handleFormatHideEmail(email) {
    const atIndex = email.indexOf("@");
    if (atIndex !== -1) {
      const name = email.slice(0, atIndex);
      const showname = email.slice(0, Math.min(name.length, 4))
      const hiddenName = "********";
      const domain = email.slice(atIndex + 1);
      const hiddenEmail = showname + hiddenName + "@" + domain;

      return hiddenEmail;
    }
    return email;
  }

  function handleGetOnlyString(string = "", defaultValue) {
    let value = string
    if (!string) {
      value = ""
    } else {
      if (/^[0-9]+$/.test(string)) {
        value = string
      } else {
        value = defaultValue || ""
      }
    }
    return value
  }

  const isEnoughInfoUser = useMemo(() => {
    return !!(user?.lastName && user?.firstName && user?.email);
  }, [user])

  return {
    handleFormatHideAccountName,
    handleFormatHideEmail,
    handleGetOnlyString,
    isEnoughInfoUser
  };
};
export default usePaymentMethodInsert;
