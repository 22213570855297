//hỉnh ảnh màn hình cửa hàng
export const APP_IMAGES = {
  ImageShopList: "/assets/images/Shop/ShopList.png",
  ImageShopMachine10: "/assets/images/Shop/ShopMachine10.png",
  ImageShopMachine50: "/assets/images/Shop/ShopMachine50.png",
  ImageShopMachine100: "/assets/images/Shop/ShopMachine100.png",
  ImageFactoryClaimPIT: "/assets/images/Factory/FactoryClaimPIT.jpg",
  ImageFactoryTop: "/assets/images/Factory/FactoryTop.png",
  ImageFactoryMachine10: "/assets/images/Factory/FactoryMachine10.png",
  ImageFactoryMachine50: "/assets/images/Factory/FactoryMachine50.png",
  ImageFactoryMachine100: "/assets/images/Factory/FactoryMachine100.png",
  ImageFactoryMachineMining10: "/assets/images/Factory/FactoryMachineMining10.png",
  ImageFactoryMachineMining50: "/assets/images/Factory/FactoryMachineMining50.png",
  ImageFactoryMachineMining100: "/assets/images/Factory/FactoryMachineMining100.png",
  imagebannerVN: "/assets/images/Introduce/bannerVN.png",
  imagebannerCN: "/assets/images/Introduce/bannerCN.png",
  imagebannerEN: "/assets/images/Introduce/bannerEN.png",
  imagebannerIN: "/assets/images/Introduce/bannerIN.png",
  imagebannerKR: "/assets/images/Introduce/bannerKR.png",
  imagebannerML: "/assets/images/Introduce/bannerML.png",
  imagebannerTL: "/assets/images/Introduce/bannerTL.png",
  imgLeaderVN: "/assets/images/LeaderBoard/leaderVN.png",
  imgLeaderCN: "/assets/images/LeaderBoard/leaderCN.png",
  imgLeaderEN: "/assets/images/LeaderBoard/leaderEN.png",
  imgLeaderIN: "/assets/images/LeaderBoard/leaderIN.png",
  imgLeaderKR: "/assets/images/LeaderBoard/leaderKR.png",
  imgLeaderML: "/assets/images/LeaderBoard/leaderML.png",
  imgLeaderTL: "/assets/images/LeaderBoard/leaderTL.png"
}